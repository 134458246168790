const LANDSCAPE = "L";
const PORTRAIT = "P";
const SQUARE = "S";
const ACTIVE = "active";
const INACTIVE = "inactive";

class GalleryCell {
    constructor(image, parent) {
        // console.log("++++++++++++++++++++ " , image);
        this.id = (image != undefined && image.id != undefined) ? image.id : Math.random().toString(36).substring(2, 15);
        this.image = image;
        this.column = parent;
        this.horizontalFix = 1;
        this.verticalFix = 1;
    }

    isFixed () {
        return this.horizontalFix!= 1 || this.verticalFix!=1;
    }

    fixLayout(newHorizontalFix, newVerticalFix) {
        this.horizontalFix = newHorizontalFix;
        this.verticalFix = newVerticalFix;
    }

    name () {
        return this.image.name;
    }

    cellType () {
        let imageType = "";
        const aspectRatio = this.image.width / this.image.height;
        if (aspectRatio > 1) {
            imageType = LANDSCAPE;
        } else if (aspectRatio < 1) {
            imageType = PORTRAIT;
        } else {
            imageType = SQUARE;
        }

        return imageType;
    }

    width () {
        const imageType = this.cellType();
        return imageType === "P" ? 1 : 2;
    }

    height () {
        const imageType = this.cellType();
        return imageType === "P" ? 2 : 1;
    }

    render() {
        return {
            name: this.image.name,
            url: this.image.url,
            width: this.image.width,
            height: this.image.height,
            cellType: this.cellType(),
            hunits: this.width(),
            vunits: this.height()
        }   
    }
}

class GalleryColumn {
    constructor (id=null, parent, maxColumnHeight) {
        this.maxColumnHeight = maxColumnHeight;
        this.parent = parent;
        this.images = [];
        this.expansionFactor = 1;
        this.id = (id == null) ? Math.random().toString(36).substr(2, 9) : id;
    }

    addImage (image, forceExpansion=false) {
        const currentImageCount = this.images.length;
        this.images.push(new GalleryCell(image, this.id));
        if (forceExpansion && this.maxColumnHeight < this.images.length)  {
            this.maxColumnHeight = this.images.length;
            this.expansionFactor = currentImageCount / this.maxColumnHeight;
            // console.log(`expanding column ${this.id} to ${this.maxColumnHeight} cells`);
        }
    }

    isFixed () {
        return (this.expansionFactor!=1) ? true : this.images.map((cell) => cell.isFixed()).reduce((total, fixed) => {
            return total || fixed;
        }, false);
    }

    fixLayout (horizontalFix, verticalFix) {
        // console.log(`fixing layout for column ${this.id} with horizontalFix:${horizontalFix} x verticalFix:${verticalFix}; expensionFactor:${this.expansionFactor}`);
        this.images.forEach((cell) => {
            cell.fixLayout(horizontalFix*this.expansionFactor, verticalFix*this.expansionFactor);
        });
    }

    isFull () {
        return this.images.map((cell) => cell.cellType()).reduce((total, cellType) => {
            const units = cellType === "P" ? 2 : 1;
            return total + units;
        }, 0) >= this.maxColumnHeight;
    }

    isCapable (image) {
        // console.log(`is capable of adding image ${image.id}`, image);
        const newCell = new GalleryCell(image,  this.id);
        const imageType = newCell.cellType();
        const imageHeight = imageType === "P" ? 2 : 1;
        return this.images.map((cell) => cell.cellType()).reduce((total, cellType) => {
            const units = cellType === "P" ? 2 : 1;
            return total + units;
        }, imageHeight) <= this.maxColumnHeight;
    }

    width () {
        return this.images.map((cell) => cell.width()).reduce((total, width) => {
            return total > width ? total : width;
        }, 0);
    }

    height () {
        const units = this.images.map((cell) => cell.height()).reduce((total, height) => {
            return total + height;
        }, 0);

        return units * this.expansionFactor;
    }

    heights () {
        return Array.from(new Set(this.images.map((cell) => cell.height())));
    }

    isValidLayout () {
        const naturalFix =  (this.expansionFactor != 1 ) ? true : (this.height() == this.maxColumnHeight && this.heights().length == 1);
        const artificialFix = this.isFixed();
        return naturalFix || artificialFix;
    }

    numberOfElements () {
        return this.images.length;
    }

    cellTypes () {
        return this.images.map((cell) => cell.cellType());
    }
        
    render () {
        return {
            column: this.id,
            width: this.width(),
            height: this.height(),
            numberOfElements: this.numberOfElements(),
            cellTypes: this.cellTypes(),
            isValidLayout: this.isValidLayout(),
            cells: this.images.map((cell) => cell.render())
        };
    }

    signature () {
        return this.cellTypes().join("");
    }    

    layout () {
        const cells = this.images.map((cell) => cell.name());
        return `[${cells.join(" ")} (${this.heights().join(" ")}, ef=${this.expansionFactor})]`;
    }    
}

class GalleryRow {
    constructor (id=null, maxRowWidth, maxRowHeight) {
        this.maxRowWidth = maxRowWidth;
        this.maxRowHeight = maxRowHeight;
        this.columns = [];
        this.id = (id == null) ? Math.random().toString(36).substr(2, 9) : id;
        this.horizontalFix = 1;
        this.verticalFix = 1;
        this.state = ACTIVE;
    }

    isFixed () {
        return this.columns.map((column) => column.isFixed()).reduce((total, fixed) => {
            return total || fixed;
        }, false);
    }

    totalFixes () {
        return this.columns.map((column) => column.isFixed()).reduce((total, fixed) => {
            return total + (fixed ? 1 : 0);
        }, 0);
    }

    fixLayout() {
        const minRowHeight = this.heights().reduce((total, height) => {
            return total < height ? total : height;
        }, 1000);

        const minRowWidth = this.widths().reduce((total, width) => {
            return total < width ? total : width;
        }, 1000);

        const horizontalFix =  minRowWidth / this.maxRowWidth;
        const verticalFix =  minRowHeight / this.maxRowHeight;

        const [direction, fix] = horizontalFix > verticalFix ? ["horizontal", 1.0/horizontalFix] : ["vertical", 1.0/verticalFix];

        // console.log(`horizontalFix: ${horizontalFix}, verticalFix: ${verticalFix}`);

        // console.log(`Fixing row ${this.id} with ${direction} fix ${fix}`);

        this.columns.filter((column) => column.height() != verticalFix || column.width() != horizontalFix).forEach((column) => {
            if (direction == "horizontal"){
                this.horizontalFix = fix;
                column.fixLayout(fix, 1);
            } else {
                this.verticalFix = fix;
                column.fixLayout(1, fix);
            }    
        });
    }

    rowWidth () {
        return this.columns.map((column) => column.width()).reduce((total, width) => {
            return total + width;
        }, 0);
    }

    rowHeight () {
        return this.columns.map((column) => column.height()).reduce((total, height) => {
            return total > height ? total : height;
        }, 0);
    }    
    
    countColumns () {
        return this.columns.length;
    }
    
    heights () {
        return Array.from(new Set(this.columns.map((column) => column.height()*this.verticalFix)));
    }

    widths () {
        return Array.from(new Set(this.columns.map((column) => column.width()*this.horizontalFix)));
    }

    expandHeight (increment) {
        this.maxRowHeight += increment;
    }
    
    numberOfElements () {
        return this.columns.map((column) => column.numberOfElements()).reduce((total, numberOfElements) => {
            return total + numberOfElements;
        }, 0);
    }

    isCapable (image) {

        const fitsInExistingColumns = this.columns.reduce((fits, cell) => {
            return fits || cell.isCapable(image);
        }, false);

        if (fitsInExistingColumns) {
            return true;
        } else {
            // Does not fit in existing cells, so check if it can fit in a new cell
            if (this.rowWidth() >= this.maxRowWidth) {
                // Row is full
                return false;
            } else {
                // Row is not full, so check if new cell can fit
                const newColumn = new GalleryColumn(null, this.id, this.maxRowHeight);
                newColumn.addImage(image);
                // const newCell = new GalleryCell(image);
                return this.rowWidth() + newColumn.width() <= this.maxRowWidth;
            }    
        }    
    }

    addImageToColumn (image, columnId, forceExpansion=false) {
        // console.log("adding image to column", columnId, image);
        const column = this.columns.find((column) => column.id == columnId);
        if (column) {
            column.addImage(image, forceExpansion);
            if (column.height() > this.maxRowHeight) {
                this.expandHeight(column.height() - this.maxRowHeight);
            }
        } else {
            // console.log(">>>>>>>>> row", this.id, "find column", columnId, "columns", this.columns.map((column) => column.id));
            throw new Error("Cannot find column", columnId);
        }
    }

    addImage (image) {
        // console.log("adding image to row", image.name)
        if (this.isCapable(image)) {
            let added = false;
            this.columns.forEach((column) => {
                if (!added && column.isCapable(image)) {
                    // console.log("       adding image to existing column", column);
                    column.addImage(image);
                    added = true;
                }
            });

            if (!added) {
                const newColumn = new GalleryColumn(`${this.id}C${this.columns.length+1}`, this.id, this.maxRowHeight);
                // console.log("            adding new column to support image", newColumn);
                newColumn.addImage(image);
                this.columns.push(newColumn);
            }
        } else {
            // console.log("row not capable");
            throw new Error("Cannot add image to row");
        }    
    }

    render () {
        if (this.state == ACTIVE) {
            return {
                row: this.id,
                width: this.rowWidth(),
                isValidLayout: this.isValidLayout(),
                height: this.columns.map((column) => column.height()).reduce((total, height) => {
                    return total > height ? total : height;
                }, 0),
                numberOfColumns: this.columns.length,
                numberOfElements: this.columns.map((column) => column.numberOfElements()).reduce((total, numberOfElements) => {
                    return total + numberOfElements;
                }, 0),
                columns: this.columns.map((column) => column.render()),
                cellTypes: this.columns.map((column) => column.cellTypes()),
            };
        } else {
            return null;
        }    
    }

    isValidLayout () {
        const naturalFix = this.rowWidth() <= this.maxRowWidth && this.rowHeight() == this.maxRowHeight && this.heights().length == 1;
        const artificialFix = this.isFixed();

        // console.log(`ID: ${this.id} | naturalFix: ${naturalFix}, artificialFix: ${artificialFix}`);
        // console.log(`    heights: ${this.heights().length},  rowHeight: ${this.rowHeight()}, maxRowHeight: ${this.maxRowHeight}`);
        // console.log(`    widths: ${this.widths().length}, rowWidth: ${this.rowWidth()}, maxRowWidth: ${this.maxRowWidth},`)

        // if (this.rowWidth() > this.maxRowWidth || this.rowHeight() != this.maxRowHeight) {
        //     console.log("    rowWidth != maxRowWidth || rowHeight != maxRowHeight");
        //     console.log(JSON.stringify(this, null, 2));
        // }

        return this.state == INACTIVE || naturalFix || artificialFix;
    }

    showLayout () {
        if (this.state == ACTIVE) {
            const columnsLayout = this.columns.map((column) => column.layout());
            return `[ ${columnsLayout.join(" | ")} heights: (${this.heights().join(" ")}; widths: (${this.widths().join(" ")} valid: ${this.isValidLayout()}); signature: ${this.signature()} ]`;
        } else {
            return "";
        }    
    }    

    signature () {
        return this.columns.map((column) => column.signature()).join("-");
    }

    markDeleted () {
        this.state = INACTIVE;
    }    
}   

class Gallery {
    constructor (name=null, maxUnitsPerRow, maxUnitsPerColumn, images=[], rederer=null) {
        this.maxUnitsPerRow = maxUnitsPerRow;
        this.maxUnitsPerColumn = maxUnitsPerColumn;
        this.layouts = {};
        this.rows = [];
        this.rederer = rederer;
        this.name = (name == null) ? Math.random().toString(36).substr(2, 9) : name;
        this.loadImages(images);
    }

    getName () {
        return this.name;
    }

    totalFixes () {
        return this.rows.map((row) => row.totalFixes()).reduce((total, fixes) => {
            return total + fixes;
        }, 0);
    }

    addImage (image) {
        if (this.rows.length === 0) {
            const newRow = new GalleryRow("R1", this.maxUnitsPerRow, this.maxUnitsPerColumn);
            newRow.addImage(image);
            this.rows.push(newRow);
        } else {
            let added = false;
            this.rows.forEach((row) => {
                if (!added && row.isCapable(image)) {
                    // console.log("adding image to capable row");
                    row.addImage(image);
                    added = true;
                } 
            });

            if (!added) {
                const newRow = new GalleryRow(`R${this.rows.length + 1}`, this.maxUnitsPerRow, this.maxUnitsPerColumn);
                // console.log("None of exsiting rows was able to take on image. Creating new row", newRow);
                newRow.addImage(image);
                this.rows.push(newRow);
            }
        }
    }

    loadImages(images) {
        images.forEach((image) => this.addImage(image));
    }

    width () {
        return this.rows.map((row) => row.rowWidth()).reduce((total, width) => {
            return total > width ? total : width;
        }, 0);
    }
    
    height () {
        return this.rows.map((row) => row.rowHeight()).reduce((total, height) => {
            return total + height;
        }, 0);
    }

    rowCount () {
        const validRows = this.rows.filter(row => row.state == ACTIVE).length;
        // console.log("validRows", validRows);
        return validRows;
    }

    isValidLayout = () => {
        return this.rows.map((row) => row.isValidLayout()).reduce((total, isValid) => {
             return total && isValid;
         }, true);
     }

    render () {
        if (this.rederer) {
            return this.rederer.render(this);
        } else {
            return JSON.stringify({
                name: `Gallery ${this.name}`,
                width: this.width(),
                height: this.height(),
                numberOfRows: this.rows.length,
                numberOfElements: this.rows.map((row) => row.numberOfElements()).reduce((total, numberOfElements) => {
                    return total + numberOfElements;
                }, 0),
                isValidLayout: this.isValidLayout(),
                rows: this.rows.filter(row => row.state == ACTIVE).map((row) => row.render())
            }, "", 2);
        }    
    }

    moveImage (image, rowId, columnId, targetRowId, targetColumnId) {
        const row = this.rows.find((row) => row.id == rowId);
        const column = row.columns.find((column) => column.id == columnId);
        const targetRow = this.rows.find((row) => row.id == targetRowId);
        const targetColumn = targetRow.columns.find((column) => column.id == targetColumnId);
        const imageIndex = column.images.findIndex((img) => img.id == image.id);

        // console.log("moveImage", image.image, rowId, columnId, targetRowId, targetColumnId);
        targetRow.addImageToColumn(image.image, targetColumn.id, true);
        targetRow.fixLayout();
        column.images.splice(imageIndex, 1);
        // this.fixLayout();
    }

    imageRowsByCountAndOrientation (count, orientation) {
        return this.rows.filter((row) => row.numberOfElements() == count && row.columns[0].cellTypes().includes(orientation));
    }

    rowColumnsByCountAndOrientation (count, orientation) {
        const result = this.rows.map((row) => row.columns.find((column) => column.numberOfElements() == count && column.cellTypes().includes(orientation)));
        return result.filter((column) => column != null);
    }

    fixLayout () {
        if (!this.isValidLayout()) {
            // console.log("Gallery layout is not valid. Fixing layout");

            this.rows.forEach((row) => {
                if (!row.isValidLayout()) {
                    const isSingleLandscapeImageRow = row.numberOfElements() == 1 && row.columns[0].cellTypes().includes(LANDSCAPE);
                    const doubleLandscapeImageRowColumns = this.rowColumnsByCountAndOrientation(2, LANDSCAPE);

                    // console.log("isSingleLandscapeImageRow", isSingleLandscapeImageRow);
                    // console.log("doubleLandscapeImageRowColumns", doubleLandscapeImageRowColumns);
                    if (isSingleLandscapeImageRow && doubleLandscapeImageRowColumns.length > 0) {
                        const image = row.columns[0].images[0];
                        const sourceRowId = row.id;
                        const sourceColumnId = row.columns[0].id;
                        const  targetRowId = doubleLandscapeImageRowColumns[0].parent;;
                        const targetColumnId = doubleLandscapeImageRowColumns[0].id;;
                        
                        // console.log("Moving image", image, "from", sourceRowId, sourceColumnId, "to", targetRowId, targetColumnId);
                        // console.log(row);
                        this.moveImage(image, sourceRowId, sourceColumnId, targetRowId, targetColumnId);
                        row.markDeleted();
                    } else {
                        row.fixLayout();
                    }    
                } 
            });
        }
    }

    showLayout () {
        let layoutString = `>>>>>>>>>>>>>>>>>>>>>>>>>> \nGallery ${this.name} layout; score=${this.score().toFixed(2)} rowCount = ${this.rowCount()}\n`;
        this.rows.forEach((row) => {
            layoutString += `${row.showLayout()}\n`;
        });
        layoutString += `<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< \n\n`;
        return layoutString;
    }    

    score () {

        // Fewer lines is better
        const totalLines = this.rows.length;
        const linesScore = 1.0/totalLines;
        const LINE_SCORE_WEIGHT = 0.25;

        // Fewer fixes is better
        const totalFixes = this.totalFixes();
        const fixesScore = (totalFixes == 0) ? 1 : 1.0/totalFixes;
        const FIXES_SCORE_WEIGHT = 0.05;

        // Fewer elements is better
        const totalElements = this.rows.map((row) => row.numberOfElements()).reduce((total, numberOfElements) => {
            return total + numberOfElements;
        }, 0);
        const totalElementsScore = 1.0/totalElements;
        const ELEMENTS_SCORE_WEIGHT = 0.30;

        // Score for the presence of adjacent alternating rows (symetrical between them)
        let signature = null;
        const alternationScore = this.rows.map((row) => {
            const rowSignature = row.signature();
            if (signature == null) {
                signature = rowSignature;
                return 1;
            } else {
                if (rowSignature == signature) {
                    return 0;
                } else {
                    signature = rowSignature;
                    if (rowSignature.split("-").reverse().join("-") == signature) {
                        return 1;
                    } else {    
                        return 0.5;
                    }    
                }
            }
        }).reduce((total, alternation) => {
            return total + alternation;
        }, 0);
        const ALTERNATION_SCORE_WEIGHT = 0.10;

        const heterogeneityScore = this.rows.map((row) => {
            const rowSignature = row.signature();
            const cellTypes = new Set(rowSignature.split("-"));
            const cellTypeCount = cellTypes.size;
            // different cell types is better
            switch (cellTypeCount) {
                case 1:
                    return 0;
                case 2:
                    return 0.75;
                case 3:
                    return 0.5;
                default:
                    return 1;
            }
        }).reduce((total, cellTypeCount) => {
            return total + cellTypeCount;
        }, 0);
        const HETEROGENEITY_SCORE_WEIGHT = 0.25;
        
        // Score for the presence of a bigger left column
        const biggerLeftColumnScoreRaw = this.rows.map((row, rowIndex) => {
            const sizes = row.columns.map((column) => column.numberOfElements());
            // console.log(`sizes=${sizes}`);
            return sizes.reduce((total, size, currentIndex) => {
                if (currentIndex==sizes.length-1) {
                    return total;
                } else {
                    if (size <= sizes[currentIndex+1]) {
                        const cellScore = (1.0/(currentIndex+1))/(rowIndex+1);
                        // console.log(`Bigger left column found in row ${row.id} at index ${currentIndex} with size ${size} and right column size ${sizes[currentIndex+1]}; cellScore=${cellScore}`);
                        return total + cellScore
                    } else {
                        return total;
                    }
                }
            }, 0);
        }).reduce((total, score) => {
            return total + score;
        }, 0);
        const biggerLeftColumnScore = biggerLeftColumnScoreRaw/this.rows.length;


        // console.log(`biggerLeftColumnScore=${biggerLeftColumnScore}`);
        // .reduce((total, score, currentIndex) => {
        //     return total + score/currentIndex;
        // }, 0);
        const BIGGER_LEFT_COLUMN_SCORE_WEIGHT = 0.05;

        const percentResult = 100*(linesScore*LINE_SCORE_WEIGHT + 
                    fixesScore*FIXES_SCORE_WEIGHT +
                    alternationScore*ALTERNATION_SCORE_WEIGHT + 
                    totalElementsScore*ELEMENTS_SCORE_WEIGHT + 
                    heterogeneityScore*HETEROGENEITY_SCORE_WEIGHT +
                    biggerLeftColumnScore*BIGGER_LEFT_COLUMN_SCORE_WEIGHT);
        
        // console.log(`linesScore=${linesScore} fixesScore=${fixesScore} alternationScore=${alternationScore} totalElementsScore=${totalElementsScore} heterogeneityScore=${heterogeneityScore} biggerLeftColumnScore=${biggerLeftColumnScore}`);

        return percentResult;                    
    }    
}

export default Gallery;