
const ICategoriesSchema = {
    title: 'Categories',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'products',
          'materials',
          'process', 
          'product_icon'
        ],
      },
    ],
  
    properties: {
      materials : {
        type: "array",
        title: "Materials",
        description: "My great field",
        minItems: 1,
        uniqueItems: true,
        additionalItems: true,
        items: {
          title: "",
          description: "",
          type: "string",
          enum: ["dotted", "diamond", "slash"],
          enumNames: ["Dotted", "Diamond", "Slash"],
          choices: [['dotted', 'Dotted'], ['diamond', 'Diamond'], ['slash', 'Slash']]
        }
      },
      products:  {
      type: "array",
      title: "Product",
      description: "My great field",
      minItems: 1,
      uniqueItems: true,
      additionalItems: true,
      items: {
        title: "",
        description: "",
        type: "string",
        enum: ["coat", "dress", "hoodie", "jumper", "night_dress", "polo", "shirt", "shorts", "skirt", "swimsuit", "trousers", "tshirt", "sweat"],
        enumNames: ["Coat", "Dress", "Hoodie", "Jumper", "Night Dress", "Polo", "Shirt", "Shorts", "Skirt", "Swimsuit", "Trousers", "T-shirt", "Sweat"],
        choices: [['coat', 'Coat'], ['dress', 'Dress'], ['hoodie', 'Hoodie'], ['jumper', 'Jumper'], ['night_dress', 'Night Dress'], ['polo', 'Polo'], ['shirt', 'Shirt'], ['shorts', 'Shorts'], ['skirt', 'Skirt'], ['swimsuit', 'Swimsuit'], ['trousers', 'Trousers'], ['tshirt', 'T-shirt'], ['sweat', 'Sweat']]
      }
    },
      process: {
        type: "array",
        title: "Processes",
        description: "My great field",
        minItems: 1,
        uniqueItems: true,
        additionalItems: true,
        items: {
          title: "",
          description: "",
          type: "string",
          enum: ["dotted", "diamond", "slash"],
          enumNames: ["Dotted", "Diamond", "Slash"],
          choices: [['dotted', 'Dotted'], ['diamond', 'Diamond'], ['slash', 'Slash']]
        }
      },
      product_icon : {
        type: "choice",
        title: "Product Icon",
        description: "My great field",
        choices: [['coat.png', 'Coat'], ['dress.png', 'Dress'], ['hoodie.png', 'Hoodie'], ['jumper.png', 'Jumper'], ['night_dress.png', 'Night Dress'], ['polo.png', 'Polo'], ['shirt.png', 'Shirt'], ['shorts.png', 'Shorts'], ['skirt.png', 'Skirt'], ['swimsuit.png', 'Swimsuit'], ['trousers.png', 'Trousers'], ['tshirt.png', 'T-shirt'], ['sweat.png', 'Sweat']]
      }
    },
  
    required: [],
  };
  
  export default ICategoriesSchema;