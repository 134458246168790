import React, { useEffect, useRef, useState, Component }  from 'react';
import PropTypes from 'prop-types';
import config from '@plone/volto/registry';
import { connect } from 'react-redux';
import { emailSend } from '@plone/volto/actions'
import {contactFormSubmit} from '../../../../actions';


import './contact-form.less';

const [INIT, SENDING, SENT, ERROR, READY] = [0,1,2,3,4];


class ContactFormView extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    error: PropTypes.string,
    state: PropTypes.number,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
   constructor(props) {
    super(props);

    this.props = {
      ...this.props,
      state: INIT,
      error: ""
    }

    this.blockClass = "contactFormBlock";
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      website: '',
      phone: '',
      message: '',
      state: INIT,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    // this.messageBody = this.messageBody.bind(this);

  }

  // messageBody() {
  //   if (this.state != null) {
  //     return `
  //       The following enquiry came through from your website:

  //       Name: ${this.state.firstName} ${this.state.lastName}
  //       Company: ${this.state.companyName}
  //       Email: ${this.state.email},
  //       Website: ${this.state.website},
  //       Phone Number: ${this.state.phone},
  //       Message:

  //       ${this.state.message}

  //       ------- END OF MESSAGE -------
  //     `;
  //   } else {
  //     return "";
  //   }
  // }

  isValidData() {
    return  this.state.firstName != "" && 
            this.state.lastName != "" && 
            this.state.companyName != "" && 
            this.state.email != "" && 
            this.isValidEmail(this.state.email) &&
            this.state.message != "";
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.state == READY) {
      let name = this.state != null ? this.state.lastName : '';
      const firstName = this.state != null ? this.state.firstName : '';
      const lastName = this.state != null ? this.state.lastName : '';
      const companyName = this.state != null ? this.state.companyName : '';
      const website = this.state != null ? this.state.website : '';
      const emailAddress = this.state != null ? this.state.email : '';
      const phoneNumber = this.state != null ? this.state.phone : '';
      const subject = `Website Enquiry from company ${companyName}`;
      // const to = 'gtportugal@gtportugal.com';
      const message = this.state != null ? this.state.message : '';

      this.setState({state: SENDING});

      // console.log("ABOUT TO CALL ACTION FOR DATA SUBMISSION. STATE IS AS FOLLOWS");
      // console.log(this.state);

      // this.props.emailSend(name, from, to, subject, message);
      // companyName, emailAddress, phoneNumber, subject, messag
      this.props.contactFormSubmit(firstName, lastName, companyName, website, emailAddress, phoneNumber, subject, message);

      // console.log("SUBMISSION COMPLETED");

      var thankYouMessage = document.getElementById("thankYouMessage");

      thankYouMessage.style.display = "block";
    }
  }
  
  isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  
  submissionClass = () => {
    return this.state.state == READY ? "submit-button enabled" : "submit-button disabled";
  }

  getInputClasses(fieldId) {
    switch(fieldId) {
      case 'fname':
        return (this.state.firstName.length > 0) ? "fname filled" : "fname mandatory";
      case 'lname':
        return (this.state.lastName.length > 0) ? "lname filled" : "lname mandatory";
      case 'cname':
        return (this.state.companyName.length > 0) ? "cname filled" : "cname mandatory";
      case 'email':
        return (this.isValidEmail(this.state.email)) ? "email filled" : "email mandatory";
      case 'message':
        return (this.state.message.length > 0) ? "message filled" : "message mandatory";
      default:
        return "";
    }
  }

  

  closeSubmissionSuccessfulMessage(e){
    e.preventDefault();

    var formInputs = document.getElementsByTagName("input");

    for (var i=0; i<formInputs.length; i++){
      formInputs[i].value = "";
    }

    var textArea = document.getElementsByTagName("textarea");

    textArea[0].value = "";

    var thankYouMessage = document.getElementById("thankYouMessage");

    thankYouMessage.style.display = "none";
  }

  setStateFromField = (event) => {
    switch(event.target.id) {
      case 'fname':
        this.setState({firstName: event.target.value});
        break;
      case 'lname':
        this.setState({lastName: event.target.value});
        break;
      case 'cname':
        this.setState({companyName: event.target.value});
        break;
      case 'email':
        this.setState({email: event.target.value});
        break;
      case 'tel':
        this.setState({phone: event.target.value});
        break;
      case 'web':
        this.setState({website: event.target.value});
        break;
      case 'message':
        this.setState({message: event.target.value});
        break;
      default:
        console.log("Can't set field with ID " + event.target.id) ;
    }

  }

  componentDidUpdate(prevProps, prevState) {
    console.log(`componentDidUpdate on MailForm`, this.state);
    if (this.state.state == INIT && this.isValidData()) {
        this.setState({state: READY});
    }  else if(this.state.state == READY && !this.isValidData()) {
        this.setState({state: INIT});
    }  
  } 

  render() {
    const { settings } = config;

    return(
      <div className={this.blockClass}>
        <div className="contact-form-container">
          <div className="company-info">
            <h2 className="heading">Get In Touch Now</h2>
            <div className="info">
              <a href="https://www.linkedin.com/company/irmaos-rodrigues/" target="new">
                <img className="linkedin" src="/Linkedin.svg" alt="linkedin"></img>
              </a>  
              <a href="https://goo.gl/maps/5Ftzz7ybHwh8mmtBA" target="new">
                <div className="location">
                  <img src="/Map.svg" alt="map"></img>
                  <div>Irmãos Rodrigues, Gilmonde, Portugal</div>
                </div>
              </a>
              <div className="phone">
                <img src="/Telephone-Stroke.svg" alt="phone"></img>
                <div>(+351) 253 830 070</div>
              </div>
            </div>
          </div>

          <form className="clientInfo">

          <div className="field-group">
                <input type="text"
                       id="fname"
                       className={this.getInputClasses('fname')}
                       name="fname"
                       placeholder="First Name"
                       onChange={this.setStateFromField}></input><br></br>
                <input className={this.getInputClasses('lname')}
                       type="text"
                       id="lname"
                       name="lname"
                       placeholder="Last Name"
                       onChange={this.setStateFromField}></input><br></br>
            </div>

            <div className="field-group">
              <input type="text" id="cname" name="cname" placeholder="Your Company Name" className={this.getInputClasses('cname')} onChange={this.setStateFromField}></input><br></br>
              <input type="text" id="web" name="web" placeholder="Your Website URL" onChange={this.setStateFromField}></input><br></br>
            </div>

            <div className="field-group">
              <input type="email" id="email" name="email" placeholder="Email Address" className={this.getInputClasses('email')} onChange={this.setStateFromField}></input><br></br>
              <input type="text" id="tel" name="tel" placeholder="Phone Number" onChange={this.setStateFromField}></input><br></br>
            </div>

            <div className="field-group">
              <textarea name="message" id="message" rows="10" cols="30" placeholder="Write your message here"  className={this.getInputClasses('message')} onChange={this.setStateFromField}></textarea><br></br>
            </div>
            <button className={this.submissionClass()}  alt="submit" onClick={this.handleSubmit}>Send</button>

          </form>
          <div id="thankYouMessage" className="submissionSuccessfullMessage">
            <button className="closeButton" alt="close" onClick={this.closeSubmissionSuccessfulMessage}>X</button>
            <h3 className="thankYouMessage">Thank you for contacting us.</h3>
          </div>
        </div>
      </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => {

  // console.log(`>>>>>>> ContactFormView mapStateToProps begin`);
  const state_transf = (emailState) => {

    if (!emailState.loaded && !emailState.loading) {
      return INIT;
    } else if (!emailState.loaded && emailState.loading) {
      return SENDING;
    } else if (emailState.loaded && !emailState.loading) {
      return SENT;
    } else {
      return ERROR;
    }
  };

  // console.log(`>>>>>>> ContactFormView state_transf declared and about to be called`);


  const newProps = {
      ...ownProps.data,
      mailSubmission: state.contactForm,
      state: (state.emailSend.error != null) ? ERROR : state_transf(state.emailSend)
  }

  // console.log(`>>>>>>> ContactFormView state_transf called OK. mapStateToProps end`);

  return newProps;
}

const mapDispatchToProps = {
  // ... normally is an object full of action creators
  // emailSend: emailSend
  contactFormSubmit: contactFormSubmit
}


export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContactFormView);
