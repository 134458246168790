import React, { useEffect, useLayoutEffect, useRef, useState }  from 'react';
import { annotatedLog, changeInlineCss, makeImageSpotVisible} from '../utilities';
import { forceSameDomain } from '../utilities';

import './divider.less';


const DividerView = (props) => {
  let ref = useRef();
  let imageRef = useRef();

  let image = "";

  const [containerClass, setContainerClass] = useState("imageDivider");
  const [imageInline, setImageInline] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0
  });


  const adjustImagePosition = () => {
    const xspot = (props.data.xspot?.trim().length>0) ? parseInt(props.data.xspot) : imageRef.current.naturalWidth/2;
    const yspot = (props.data.yspot?.trim().length>0) ? parseInt(props.data.yspot) : imageRef.current.naturalHeight/2;

    // set a container width based on window state
    const containerWidth = Math.min(2000 /* MAX PAGE WIDTH */, windowSize.width);

    try {
      const imageAdjust = makeImageSpotVisible( //ref.current.clientWidth, 
        containerWidth,
        ref.current.clientHeight, 
        imageRef.current.naturalWidth, 
        imageRef.current.naturalHeight, 
        1.0, 
        0, 
        0, 
        xspot, 
        yspot);

      // annotatedLog("triggerImagePosCalculations", `%%%%%%%%%%% RESULTS ${JSON.stringify(imageAdjust)}`);  
      changeInlineCss(imageInline, setImageInline, 
                {
                  marginLeft: `${Math.round(imageAdjust.left)}px`,
                  marginTop: `${Math.round(imageAdjust.top)}px`,
                  scale: `${imageAdjust.scale}`
                }, 
                []
      );

    } catch (e) {
      annotatedLog("triggerImagePosCalculations", "adjustImagePosition error", e.toString());
    }
    
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {

      const handleWindowResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }  
  }, []);
  
  useLayoutEffect(() => {
    if (imageLoaded==true) {
      adjustImagePosition();
    }
  }, [imageLoaded, windowSize])

  useLayoutEffect(() => {

    // console.log(`>>>>>>> DividerView, props.data: ${JSON.stringify(props.data)}`);
    
    const greyscale = props.data.greyscale || false;
    const flip = props.data.flip || false;
  
    const flipClass = flip ? "flip" :"";
    const greyscaleClass = greyscale ? "greyscale" :"";  
    
    setContainerClass (`imageDivider ${greyscaleClass} ${flipClass}`.trim());
    if (imageRef.current.naturalWidth > 0) {
      setImageLoaded(true);
    }
  }, []);

  try {
    image = forceSameDomain(props.data.image[0]["getURL"] + "/@@images/image");
  } catch (e) {
    image = ""
  }

  return( <div ref={ref} className={containerClass}>
            <img counter="0" src={image} alt="" style={imageInline} ref={imageRef} onLoad={(event) => setImageLoaded(true)}/>
          </div>)
};

export default DividerView;