const IFAQSchema = {
    title: 'FAQ',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'title',
          'theme',
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      theme: {
        title: 'Theme',
        description: 'Theme of the FAQ',
        type: 'choice',
        choices: [['light', 'Light'], ['dark', 'Dark']]
      },

      title: {
        title: 'Title',
        description: 'Title to display',
        widget: 'textarea',
      },
    },
  
    required: ['theme', 'title'],
  };
  
  export default IFAQSchema;