const IDividerSchema = {
    title: 'Divider',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'image',
          'xspot',
          'yspot',
          'flip',
          'narrow',
          'greyscale',
        ],
      },
    ],
  
    properties: {
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      xspot: {
        title: 'X Hotspot',
        description: 'X coordinate of image that must be visible',
        widget: 'text',
      },

      yspot: {
        title: 'Y Hotspot',
        description: 'Y coordinate of image that must be visible',
        widget: 'text',
      },

      flip: {
        title: 'Flip Image',
        description: 'Flip image horizontally',
        type: 'boolean',
      },

      narrow: {
        title: 'Narrow Hero',
        description: 'Reduce hero height',
        type: 'boolean',
      },

      greyscale: {
        title: 'Greyscale',
        description: 'B&W image',
        type: 'boolean',
      },
      
    },
  
    required: ['image'],
  };
  
  export default IDividerSchema;