/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import pages from './content_types/pages';
import contactForm from './comms/mail';
import pendingImages from './performance/images';
import metadata from './content_types/metadata';

// import vocabularies from './vocabularies/vocabularies';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  pages,
  contactForm,
  pendingImages,
  metadata,
  // vocabularies,
  // Add your reducers here
};

export default reducers;
