const IVerticalDoubleImageHighlightSchema = {
    title: 'VerticalDoubleImageHighlight',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'portrait_image',
          'landscape_image',
          'text',
          'bold_words',
          'colorize',
          'text_left',
          'cta',
          'target_url'
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      portrait_image: {
        title: 'Portrait Image',
        description: 'Choose Portrait Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      landscape_image: {
        title: 'Landscape Image',
        description: 'Choose Landscape Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      text: {
        title: 'Text',
        description: 'Text',
        widget: 'text',
      },

      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },

      colorize: {
        title: 'Color Bold',
        description: 'Uses color instead of font-style for emphasis',
        type: 'boolean',
      },

      text_left: {
        title: 'Text Left',
        description: 'Align text left',
        type: 'boolean',
      },

      cta: {
        title: 'Call to Action',
        description: 'Call to Action / Link Text',
        widget: 'text',
      },

      target_url: {
        title: 'Target URL',
        description: 'Choose Target Page for Link',
        widget: 'object_browser',
        selectableTypes : ['page'], 
        mode: 'link'
      },
      
    },
  
    required: ['portrait_image', 'landscape_image', 'text'],
  };
  
  export default IVerticalDoubleImageHighlightSchema;