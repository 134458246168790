import { GET_CONTENT_METADATA } from '../../constants/ActionTypes';

export function getContentMetadata(uidArray) {
    const params = (uidArray === undefined) ? '' : uidArray.map(uid => `UID=${uid}`).join('&') + '&fullobjects=1';
    return {
      type: GET_CONTENT_METADATA,
      request: {
        op: 'get',
        path: `/@search?${params}`,
      },
   };
  }