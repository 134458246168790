import React, { useEffect, useRef, useState }  from 'react';
import { forceSameDomain } from '../utilities';
import './fading-image.less';


const FadingImageView = (props) => {
  let ref = useRef();

  // console.log(">>>>>>>> FadingImageView type of props is " + typeof props + "; PROPS");
  // console.log(props);
  // console.log(">>>>>>>> FadingImageView DATA");
  // console.log(props.data);


  let image = "";
  
  try {
    image = forceSameDomain(props.data.image[0]["getURL"] + "/@@images/image");
    
  } catch (e) {
    image = ""
  }

  const direction = (props.data.direction != undefined) ? props.data.direction : 'rtl';
  const inlineClasses = (props.data.inline_classes != undefined) ? props.data.inline_classes  : "";
  
  const blockClass = `fading-img ${direction} ${inlineClasses}`;

  return( <div className={blockClass}>
          <img counter="0" src={image} alt=""/>
        <img counter="1" src={image} alt=""/>
        <img counter="2" src={image} alt=""/>
        <img counter="3" src={image} alt=""/>
         </div>)
};

export default FadingImageView;