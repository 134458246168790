/** Image Gallery actions.
* @module actions/content_types/gallery_images
*/

import { CONTACT_FORM_SUBMIT } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function contactFormSubmit
* @returns {Object} Contact Form action.
*/
export function contactFormSubmit(firstName, lastName,companyName, website, emailAddress, phoneNumber, subject, body) {
 return {
   type: CONTACT_FORM_SUBMIT,
   request: {
     op: 'post',
     path: `/@@mail`,
     data: {
        firstName,
        lastName,
        companyName,
        website,
        emailAddress, 
        phoneNumber,
        subject,
        body,
      },
   },
 };
}


