import React, { useEffect, useRef, useState }  from 'react';
import './categories.less';

const CategoriesView = (props) => {
  let ref = useRef();
  // const [stitch, setStitch] = useState("");

  const segment = (props.properties.fsegments!=undefined && props.properties.fsegments.length>0) ? props.properties.fsegments[0]["title"] : null;
  const genre = (props.properties.fgenres!=undefined && props.properties.fgenres.length>0) ? props.properties.fgenres[0]["title"] : null;
  const structure = (props.properties.fstructures!=undefined && props.properties.fstructures.length>0) ? props.properties.fstructures[0]["title"] : null;
  const composition = (props.properties.fcompositions!=undefined && props.properties.fcompositions.length>0) ? props.properties.fcompositions[0]["title"] : "Composition";
  const addon = (props.properties.faddons!=undefined && props.properties.faddons.length>0) ? props.properties.faddons[0]["title"] : "Decorative";

  const fabricIcon = '/processicon.svg';
  const atomIcon = '/atom-react.svg';
  // const laserIcon = '/loading-process.svg';
  const laserIcon2 = '/loading-indicator.svg';
  const personIcon = '/button-circle-person.svg';
  // const tagIcon = '/clothes-tag.svg';
  const barcodeIcon = '/barcode.svg';

  // console.log(">>>> CategoriesView. Here's props.properties:")
  // console.log(props.properties);

  return( segment!=null && genre!=null && structure!=null && 
        <div className='categoriesBlock'>
          {/* <div className='category-div products'>
            <div className='productIcon icon'>
              <img src={personIcon} alt="" />
            </div>
            <div className='categoryName productName'>
              <a>{segment}</a>
            </div>
         </div>
         <div className='category-div materials'>
          <div className='materialIcon icon'>
              <img src={barcodeIcon} alt="" />
            </div>
          <div className='categoryName materialName'>
            <a>{genre}</a>
          </div>
         </div>
         <div className='category-div fabric'>
          <div className='processIcon icon'>
              <img src={fabricIcon} alt="" />
          </div>
          <div className='categoryName processName'>
            <a>{structure}</a>
          </div>
         </div>
         <div className='category-div process'>
          <div className='processIcon icon'>
            <img src={atomIcon} alt="" />
          </div>
          <div className='categoryName processName'>
            <a>{composition}</a>
          </div>
         </div>
         <div className='category-div process'>
          <div className='processIcon icon'>
            <img src={laserIcon2} alt="" />
          </div>
          <div className='categoryName processName'>
            <a>{addon}</a>
          </div>
         </div> */}

          <div className="related-content-card-tags">
            <div className="tag-icon">
              <img src="/clothes-tag.svg"/>
            </div>
            <div className="tag-names">
              <a className="product-tag" href="/search?fashionProductSegments=boy">Boy</a>
              <a className="product-tag" href="/search?fashionProductGenres=trousers">Trousers</a>
              <a className="product-tag" href="/search?fashionProductStructures=fleece">Fleece</a>
            </div>
          </div>

        </div>)
};

export default CategoriesView;

