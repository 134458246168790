/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

import {
  customBlocks as customBlocks,
} from '@glisco-ir/custom-blocks';

import { config } from '@storybook/addon-actions';

import { GoogleAnalyticsInsertion, PageLoaderInsertion } from './components';


import { ImageWithTextEdit, 
        ImageWithTextView, 
        FadingImageEdit, 
        FadingImageView, 
        HeroIREdit, 
        HeroIRView, 
        HomeHeroEdit, 
        HomeHeroView, 
        CTAEdit, 
        CTAView, 
        RelatedContentCardsEdit, 
        RelatedContentCardsView, 
        ProductCardsEdit, 
        ProductCardsView, 
        ImageFadeImageEdit, 
        ImageFadeImageView, 
        CategoriesEdit, 
        CategoriesView, 
        ImageIREdit, 
        ImageIRView, 
        ImageWith2TextsEdit, 
        ImageWith2TextsView} from "@glisco-ir/custom-blocks";

import sliderSVG from "@plone/volto/icons/slider.svg";
import cameraSVG from "@plone/volto/icons/camera.svg";


const unwatedDefaultBlocks = ["toc", "hero", "maps", "table", "leadimage" ];
const googleAnalyticsMeasurementID = process.env.RAZZLE_GA4_CODE || "G-PS2Z8DG034";

// import {
//   settings as defaultSettings,
// } from '@plone/volto/src/config';


// export const settings = {
//   ...defaultSettings,
// };



export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  config.blocks.blocksConfig.imagewithtext = {
    id: "imagewithtext",
    title: "Image With Text",
    icon: sliderSVG,
    group: "common",
    view: ImageWithTextView,
    edit: ImageWithTextEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.fadingimage = {
    id: "fadingimage",
    title: "Fading Image",
    icon: sliderSVG,
    group: "common",
    view: FadingImageView,
    edit: FadingImageEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.heroir = {
    id: "heroir",
    title: "Hero IR",
    icon: sliderSVG,
    group: "common",
    view: HeroIRView,
    edit: HeroIREdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.imagefadeimage = {
    id: "imagefadeimage",
    title: "Image Fade Image",
    icon: sliderSVG,
    group: "common",
    view: ImageFadeImageView,
    edit: ImageFadeImageEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };
  
  config.blocks.blocksConfig.calltoaction = {
    id: "calltoaction",
    title: "CTA",
    icon: sliderSVG,
    group: "common",
    view: CTAView,
    edit: CTAEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.relatedcontentcards = {
    id: "relatedcontentcards",
    title: "Related Content Cards",
    icon: sliderSVG,
    group: "common",
    view: RelatedContentCardsView,
    edit: RelatedContentCardsEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.productcards = {
    id: "productcards",
    title: "Product Cards",
    icon: sliderSVG,
    group: "common",
    view: ProductCardsView,
    edit: ProductCardsEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.categories = {
    id: "categories",
    title: "Categories",
    icon: sliderSVG,
    group: "common",
    view: CategoriesView,
    edit: CategoriesEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.imageir = {
    id: "imageir",
    title: "Image IR",
    icon: cameraSVG,
    group: "common",
    view: ImageIRView,
    edit: ImageIREdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.imagewith2texts = {
    id: "imagewith2texts",
    title: "Image With 2 Texts",
    icon: cameraSVG,
    group: "common",
    view: ImageWith2TextsView,
    edit: ImageWith2TextsEdit,
    restricted: false,
    mostUsed: true,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['en', 'pt'],
    defaultLanguage: 'en'
  }
  
  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: GoogleAnalyticsInsertion,
      props: {
        'googleMeasurementId': googleAnalyticsMeasurementID
      }
    },
    {
      match: '',
      component: PageLoaderInsertion,
      props: {
        'mySampleProp': "hardocded value"
      }
    },
  ]


  return config;
}
