const IFadingImageSchema = {
    title: 'FadingImage',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'image',
        ],
      },
    ],
  
    properties: {
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      
    },
  
    required: ['image'],
  };
  
  export default IFadingImageSchema;