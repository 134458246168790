const IHeroIRSchema = {
    title: 'HeroIR',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'image',
          'title',
          'horizontal_offset',
          'vertical_offset',
          'horizontal_offset_new_text',
          'vertical_offset_new_text',
          'breadcrumbs_bottom',
          'image_left'
        ],
      },
    ],
  
    properties: {
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      title: {
        title: 'Title',
        description: 'Title',
        widget: 'text',
      },
      horizontal_offset: {
        title: 'Text Horizontal Offset',
        description: 'horizontal offset of the text',
        widget: 'int',
      },
      vertical_offset: {
        title: 'Text Vertical Offset',
        description: 'vertical offset of the text',
        widget: 'int',
      },
      horizontal_offset_new_text: {
        title: 'New Text Horizontal Offset',
        description: 'horizontal offset of the first text',
        widget: 'int',
      },
      vertical_offset_new_text: {
        title: 'New Text Vertical Offset',
        description: 'vertical offset of the first text',
        widget: 'int',
      },
      breadcrumbs_bottom: {
        title: 'Breadcrumbs Bottom',
        description: 'Shift breadcrumbs to bottom of boolean',
        type: 'boolean',
      },
      image_left: {
        title: 'Image Left',
        description: 'Align image left, text right',
        type: 'boolean',
      },
      
    },
  
    required: ['image', 'title'],
  };
  
  export default IHeroIRSchema;