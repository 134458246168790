const IProductCardsSchema = {
    title: 'ProductCards',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'selector',
        ],
      },
    ],
  
    properties: {
      selector: {
        title: 'Selector Tag',
        description: 'The tag used to select content, e.g. "techniques" or "fabrics"',
        widget: 'text'
      },
    },
  
    required: ['selector'],
  };
  
  export default IProductCardsSchema;