const IRelatedContentCardsSchema = {
    title: 'RelatedContentCards',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'reverseTitle',
          'carrousel',
          'selector',
          'maxItems',
          'groupByTaxonomy'
        ],
      },
    ],
  
    properties: {
      title: {
        title: 'Title',
        description: 'The title heading up the section',
        widget: 'text'
      },

      reverseTitle: {
        title: 'Reverse Title',
        description: 'Sets title as white text on black background',
        type: 'boolean',
      },


      carrousel: {
        title: 'Use Carrousel View',
        description: 'Horizontal Scroll Carousel',
        type: 'boolean',
      },

      selector: {
        title: 'Selector Tag',
        description: 'The tag used to select content, e.g. "techniques" or "fabrics"',
        widget: 'text'
      },

      maxItems: {
        title: 'Maximum Items',
        description: 'Maximum number of items to display',
        widget: 'text'
      },

      groupByTaxonomy: {
        title: 'Group by Taxonomy/Vocabulary',
        description: 'The full name of the plone vocabulary. eg: glisco.plone.ecommerce.fashion.product.genres',
        widget: 'text'
      },
    },
  
    required: ['selector'],
  };
  
  export default IRelatedContentCardsSchema;