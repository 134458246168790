const ICTASchema = {
    title: 'CTA',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'firstText',
          'secondText',
          'link',
          'linkText',
        ],
      },
    ],
  
    properties: {
      firstText: {
        title: 'First Text',
        type: 'string',
        widget: 'text',
      },
      secondText: {
        title: 'Second Text',
        type: 'string',
        widget: 'text',
      },
      link: {
        title: 'Link',
        widget: 'object_browser',
        mode: "link",
      },
      linkText: {
        title: 'Link Text',
        type: 'string',
        widget: 'text',
      }
      
    },
  
    required: ['firstText', 'secondText', 'link', 'linkText'],
  };
  
  export default ICTASchema;