// javascrip class for the gallery
// import { grid, images } from "./gallery-data";

import Gallery from "./gallery-logic";


const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
}

const generateConfigs = (images, numberOfConfigs) => {
    let configs = [];
    for(let i = 0; i < numberOfConfigs; i++) {
        const length = images.length;
        const indexes = Array.from({length}, (v, k) => k);
        const randomSortedIndexes = indexes.sort(() => Math.random() - 0.5);
        configs.push(randomSortedIndexes);
    }

    const configSet = new Set(configs);
    return [...configSet];
}

const generateLayout = (layoutName, grid, config) => {

    const placeImage = (grid, layoutName, imageIndex) => {
        let imageType = "";
        const image = grid.images.files[imageIndex];
        const aspectRatio = image.width / image.height;
        const layout = grid.layouts[layoutName];
        if (aspectRatio > 1) {
            imageType = "L";
        } else if (aspectRatio < 1) {
            imageType = "P";
        } else {
            imageType = "S";
        }

        const imageWidth = imageType === "P" ? 1 : 2;
        
        let currentLayout = layout.layout;

        if (currentLayout.length === 0) {
            currentLayout.push([[imageType, imageIndex]]);
        } else {
            let inserted = false;
            currentLayout.forEach((row, rowIndex) => {

                // Brand new row, no images on it. Add image to row.
                if (!inserted && row.length === 0) {
                    row.push([imageType, imageIndex]);
                    inserted = true;
                } else {
                    // Calculate the width of the row, including new image to add.
                    const rowWidth = row.reduce((total, image) => {
                        const units = image[0] === "L" ? 2 : 1;
                        return total + units;
                    }, imageWidth);

                    if (!inserted && rowWidth <= grid.columns ) {
                        row.push([imageType, imageIndex]);
                        inserted = true;
                    } else {
                        if (!inserted) {
                            currentLayout.push([[imageType, imageIndex]]);
                            inserted = true;
                        }
                    }
                }
            });
        }    


        
        return grid;
    }

    grid.layouts[layoutName] = {
        config: config,
        layout: [[]],
        isValidLayout: false
    }
    
    config.forEach((imageIndex) => {
        const image = grid.images.files[imageIndex];
        grid = placeImage(grid, layoutName, imageIndex);
    });

    // grid = placeImage(images, grid, image);
    
    return grid;
}

const layoutGenerator = (grid, numberOfConfigs=10) => {
    const configs = generateConfigs(grid.images.files, numberOfConfigs);

    configs.forEach((config, key) => {
        grid = generateLayout(`Layout-${key}`, grid, config);
    });

    return grid;
}

const imageSlice = (images, startIndex, endIndex) => {
    return images.files.slice(startIndex, endIndex);
}

// const imageSliceGenerator = (images, numberOfSlices) => {
//     const slices = [];
//     const length = images.files.length;
//     const sliceLength = Math.ceil(length / numberOfSlices);
//     for(let i = 0; i < numberOfSlices; i++) {
//         const startIndex = i * sliceLength;
//         const endIndex = startIndex + sliceLength;
//         slices.push(imageSlice(images, startIndex, endIndex));
//     }
//     return slices;
// }

// const firstImageSlice = (images) => {
//     return imageSlice(images, 0, 1);
// }

const generateGalleries = (grid, numberOfGalleries) => {
    const galleries = [];
    const newGrid = layoutGenerator(grid, numberOfGalleries)
    const configs = Object.keys(newGrid.layouts).map((key) => newGrid.layouts[key].config);
    configs.forEach((config, key) => {
        const images = config.map((imageIndex) => grid.images.files[imageIndex]);
        const name = images.map((image) => image.name).join("-");
        const gallery = new Gallery(name, grid.columns, grid.rows, images);
        galleries.push(gallery);
    });    
    return galleries;
}

const bestGalleryOutOfSet = (grid, setSize) => {
    const sortByScore = (a, b) => {
        return a.score() - b.score();
    }
    
    const sortByRowCount = (a, b) => {
        return a.rowCount() - b.rowCount();
    }
    
    const sortByFixes = (a, b) => {
        return a.totalFixes() - b.totalFixes();
    }

    const galleries = generateGalleries(grid, setSize);
    galleries.forEach((gallery) => {
        gallery.fixLayout();
    });
    const prioritisedGalleries = galleries.sort(sortByScore).reverse();
    const scores = prioritisedGalleries.map((gallery) => {
        return {
            id: gallery.id,
            score: gallery.score(),
            rowCount: gallery.rowCount(),
            totalFixes: gallery.totalFixes(),
        }
    });
    const bestGallery = prioritisedGalleries[0];
    // console.log(scores);
    return bestGallery;
}

export default bestGalleryOutOfSet;
