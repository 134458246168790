const IImageWithTextSchema = {
    title: 'ImageWithText',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'alignment', 
          'image',
          'text',
          'white_text',
          'white_background',
          'bold_words'
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      alignment: {
        title: 'Alignment',
        description: 'Alignment of the image',
        type: 'choice',
        choices: [['left', 'Left'], ['right', 'Right']]
      },
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      text: {
        title: 'Text',
        description: 'Text to display',
        widget: 'textarea',
      },
      white_text: {
        title: 'White Text',
        description: 'Make text white',
        type: 'boolean',
      },
      white_background: {
        title: 'White Text Backrgound',
        description: 'Make text background white',
        type: 'boolean',
      }, 
      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },      
    },
  
    required: ['alignment', 'image', 'text'],
  };
  
  export default IImageWithTextSchema;