import React, { useEffect, useRef, useState }  from 'react';
import { useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { default as ReactGA4 } from 'react-ga4';
import config from '@plone/volto/registry';

const GoogleAnalyticsInsertion = (props) => {
    const ref = useRef();
    let location = useLocation();
    const contactForm = useSelector((state) => state.contactForm);


    // in seconds. Default: 6 months
    const cookieExpires = config.settings?.['volto-google-analytics']?.cookieExpires ?? 6 * 30 * 24 * 60 * 60; 

    // This should be taken from props instead of env
    const GA4_trackingCode = props.googleMeasurementId;

    // Initialize GA4
    useEffect(() => {
        // Only run on client side
        // console.log("GoogleAnalyticsInsertion props: " , props);

        if (__CLIENT__) {
            // console.log("GA4_trackingCode setup: " + GA4_trackingCode)

            if (GA4_trackingCode) {
                ReactGA4.initialize([
                {
                    testMode: __DEVELOPMENT__ ?? false,
                    trackingId: GA4_trackingCode,
                    gaOptions: {
                        anonymizeIp: true,
                        cookieExpires: cookieExpires,
                    },
                },
                ]);
            }
        }
      }, []);

    // Trigger GA execution if URL changes
    useEffect(() => {
        // console.log("GA4 URL Change: " + GA4_trackingCode)
        if (GA4_trackingCode) {
          ReactGA4.send({ hitType: 'pageview', page: location.pathname });
        }
      }, [location]);

    useEffect(() => {
      console.log(`GoogleAnalyticsInsertion -> useEffect[contactForm]`, contactForm);
      // try {
      //   ReactGA4.event({
      //     category: category,
      //     action: action,
      //     label: label,
      //   });
      //   console.log(`GoogleAnalyticsInsertion -> useEffect[contactForm]`, contactForm);
      // } catch (ex) {
      //   console.log(`GoogleAnalyticsInsertion -> useEffect[contactForm] execption`, ex);
      // }
    
    }, [contactForm])

    return(<div ref={ref} className="GA4Script"></div>)

}


export default GoogleAnalyticsInsertion;