const IImageTextLinkSchema = {
    title: 'ImageTextLink',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'useAsHero',
          'image',
          'fade_effect',
          'flip_image',
          'text',
          'bold_words',
          'colorize',
          'text_right',
          'cta',
          'target_url'
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      useAsHero: {
        title: 'Behave as hero component ',
        description: 'Makes all title bold. 100vh on mobile',
        type: 'boolean',
      },


      image: {
        title: 'Portrait Image',
        description: 'Choose Portrait Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      fade_effect: {
        title: 'Use Fading Image Effect',
        description: 'Renders <FadingImage> instead of <img>',
        type: 'boolean',
      },

      flip_image: {
        title: 'Flip Image Horizontally',
        description: 'Flip Image Horizontally',
        type: 'boolean',
      },

      text: {
        title: 'Text',
        description: 'Text',
        widget: 'text',
      },

      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },

      colorize: {
        title: 'Color Bold',
        description: 'Uses color instead of font-style for emphasis',
        type: 'boolean',
      },

      text_right: {
        title: 'Text Right',
        description: 'Align text right, image left',
        type: 'boolean',
      },

      cta: {
        title: 'Call to Action',
        description: 'Call to Action / Link Text',
        widget: 'text',
      },

      target_url: {
        title: 'Target URL',
        description: 'Choose Target Page for Link',
        widget: 'object_browser',
        selectableTypes : ['page'], 
        mode: 'link'
      },
      
    },
  
    required: ['image', 'text'],
  };
  
  export default IImageTextLinkSchema;