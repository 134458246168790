const IHomeHeroSchema = {
    title: 'HomeHero',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'image',
          'xspot',
          'yspot',
          'flip',
          'narrow',
          'greyscale',
          'overlay',
          'title',
          'smalltext',
          'text_left',
          'dark_text'
        ],
      },
    ],
  
    properties: {

      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      xspot: {
        title: 'X Hotspot',
        description: 'X coordinate of image that must be visible',
        widget: 'text',
      },

      yspot: {
        title: 'Y Hotspot',
        description: 'Y coordinate of image that must be visible',
        widget: 'text',
      },

      flip: {
        title: 'Flip Image',
        description: 'Flip image horizontally',
        type: 'boolean',
      },

      narrow: {
        title: 'Narrow Hero',
        description: 'Reduce hero height',
        type: 'boolean',
      },

      greyscale: {
        title: 'Greyscale',
        description: 'B&W image',
        type: 'boolean',
      },

      overlay: {
        title: 'Overlay',
        description: 'Darken/Lighten Top Left',
        type: 'choice',
        choices: [['darken', 'Darken'], ['lighten', 'Lighten'], ['none', 'None']]

      },
      
      title: {
        title: 'Title',
        description: 'Title',
        widget: 'text',
      },

      smalltext: {
        title: 'Small text',
        description: 'Use small font',
        type: 'boolean',
      },


      text_left: {
        title: 'Text Left',
        description: 'Align text left',
        type: 'boolean',
      },

      dark_text: {
        title: 'Dark Text',
        description: 'Use dark text',
        type: 'boolean',
      }

      
    },
  
    required: ['image', 'title'],
  };
  
  export default IHomeHeroSchema;