const IImageFadeImageSchema = {
    title: 'ImageFadeImage',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'image',
          'image2',
          'zoom_effect',
          'out_transition',
          'text',
          'bold_words'
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },
      
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      image2: {
        title: 'Image2',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      zoom_effect: {
        title: 'Zoom in/out effect',
        description: 'Zoom in on Image 1 and out on Image 2',
        type: 'boolean',
      },

      out_transition : {
        type: "array",
        title: "Out Transition",
        description: "How the component leaves the scene",
        minItems: 1,
        uniqueItems: true,
        additionalItems: false,
        items: {
          title: "",
          description: "",
          type: "string",
          enum: ["reduce65", "reduce0"],
          enumNames: ["Reduce65", "Reduce0"],
          choices: [['reduce65', 'Reduce65'], ['reduce0', 'Reduce0']]
        }
      },

      transition_direction: {
        title: 'Transition image right-sided',
        description: 'Aligns image right and text left, opposite the default',
        type: 'boolean',
      },

      text: {
        title: 'Text',
        description: 'Text to display',
        widget: 'textarea',
      },

      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },
      
    },
  
    required: ['image', 'image2'],
  };
  
  export default IImageFadeImageSchema;