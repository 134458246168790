 import { GET_PENDING_IMAGES, SET_PENDING_IMAGES, REGISTER_PENDING_IMAGES, REGISTER_IMAGE_LOADED } from '../../constants/ActionTypes';
 import { union, difference } from '../../addons/custom-blocks/src/utilities';

 const initialState = {
   page: null,
   pending: 0,
   progress: 0,
   requiredUrls: new Set([]),
   loadedUrls: new Set([]),
   allLoaded: false,
   lastestAction: null,
 };

 
 export default function pendingImages(state = initialState, action = {}) {

   switch (action.type) {


      case `${GET_PENDING_IMAGES}`:
        const currentState =  {
          ...state,
          lastestAction: action.type,
        }
        
        return currentState;

      case `${SET_PENDING_IMAGES}`:
      // console.log(`pendingImages reducer: pending=${action.number} && allLoaded=${action.allLoaded}`);
        const newRequiredUrls = new Set(action.images);
        const loadedUrls = new Set([]);
  
        const newPendingState =  {
            ...state,
            page: action.page,
            pending: newRequiredUrls.size,
            progress: 0 ,
            requiredUrls: newRequiredUrls,
            loadedUrls: loadedUrls,
            allLoaded: false,
            lastestAction: action.type,
       }

      //  console.log(`SET_PENDING_IMAGES reducer [${action.type}] newRequiredUrls[${newRequiredUrls.size}]. state, action,`, newPendingState, action);


       return newPendingState;

       case `${REGISTER_PENDING_IMAGES}`:
        const newRequiredImages = union(new Set(action.images), state.requiredUrls);
        const imagesLeft = difference(newRequiredImages, state.loadedUrls);
    
        const newRegisteredState= {
            ...state,
            page: action.page,
            pending: imagesLeft.size,
            progress: (newRequiredImages.size==0) ? 0 : (newRequiredImages.size-imagesLeft.size)/newRequiredImages.size,
            requiredUrls: newRequiredImages,
            loadedUrls: state.loadedUrls,
            allLoaded: imagesLeft.size===0,
            lastestAction: action.type,
        }  

        // console.log(`REGISTER_PENDING_IMAGES reducer (1) [${action.type}]. state, action, newRequiredUrls[${newRequiredImages.size}]`, newRegisteredState, action);


        return newRegisteredState;

      case `${REGISTER_IMAGE_LOADED}`:
        const newLoadedUrls = union(new Set(action.images), state.loadedUrls);
        const newPendingImages = difference(state.requiredUrls, newLoadedUrls).size;
        const newProgress = (state.requiredUrls.size==0) ? 0 : (state.requiredUrls.size-newPendingImages)/state.requiredUrls.size;

        const newState = {
            ...state,
            page: action.page,
            pending: newPendingImages,
            progress: newProgress, 
            requiredUrls: state.requiredUrls,
            loadedUrls: newLoadedUrls,
            allLoaded: newPendingImages===0,
            lastestAction: action.type,
        }

        // console.log(`REGISTER_IMAGE_LOADED reducer [${action.type}][${newLoadedUrls.size}] (progress[${newProgress}]). state, action`, newState, action);

        return newState;

     default:
       return state;
   }
 }