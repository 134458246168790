/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

 export const GET_PAGES = 'GET_PAGES';
 export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
 export const GET_VOCABULARY = 'GET_VOCABULARY';
 export const GET_VOCABULARY_SUCCESS = 'GET_VOCABULARY_SUCCESS';
 export const CONTACT_FORM_SUBMIT = 'CONTACT_FORM_SUBMIT';
 export const CONTACT_FORM_SUBMIT_SUCCESS = 'CONTACT_FORM_SUBMIT_SUCCESS';
 export const GET_PENDING_IMAGES = 'GET_PENDING_IMAGES';
 export const SET_PENDING_IMAGES = 'SET_PENDING_IMAGES';
 export const REGISTER_PENDING_IMAGES = 'REGISTER_PENDING_IMAGES';
 export const REGISTER_IMAGE_LOADED = 'REGISTER_IMAGE_LOADED';
 export const SET_PENDING_IMAGES_SUCCESS = 'SET_PENDING_IMAGES_SUCCESS';
 export const GET_CONTENT_METADATA = 'GET_CONTENT_METADATA';
 export const GET_CONTENT_METADATA_SUCCESS = 'GET_CONTENT_METADATA_SUCCESS';