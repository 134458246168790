const IImageWith2TextsSchema = {
    title: 'ImageWith2Texts',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title', 
          'preamble', 
          'bold_words',
          'text_1_alignment', 
          'text_2_alignment',
          'image',
          'text',
          'text2',
          'white_text',
          'white_background',
          'text_1_horizontal_offset',
          'text_1_vertical_offset',
          'text_2_horizontal_offset',
          'text_2_vertical_offset',
        ],
      },
    ],
  
    properties: {
      title: {
        title: 'title',
        description: 'Title to display before Image',
        widget: 'text',
      },
      
      preamble: {
        title: 'Preamble',
        description: 'Text to display before Image',
        widget: 'textarea',
      },
      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },
      text_1_alignment: {
        title: 'Text 1 Alignment',
        description: 'Alignment of the first Text',
        type: 'choice',
        choices: [['left', 'Left'], ['right', 'Right']]
      },
      text_2_alignment: {
        title: 'Text 2 Alignment',
        description: 'Alignment of the second text',
        type: 'choice',
        choices: [['left', 'Left'], ['right', 'Right']]
      },
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      text: {
        title: 'Text',
        description: 'Text to display',
        widget: 'text',
      },
      text2: {
        title: 'Text2',
        description: 'Text2 to display',
        widget: 'text2',
      },
      white_text: {
        title: 'White Text',
        description: 'Make text white',
        type: 'boolean',
      },
      white_background: {
        title: 'White Text Background',
        description: 'Make text background white',
        type: 'boolean',
      },
      text_1_horizontal_offset: {
        title: 'Text 1 Horizontal Offset',
        description: 'horizontal offset of the first text',
        widget: 'integer',
      },
      text_1_vertical_offset: {
        title: 'Text 1 Vertical Offset',
        description: 'vertical offset of the first text',
        widget: 'integer',
      },
      text_2_horizontal_offset: {
        title: 'Text 2 Horizontal Offset',
        description: 'horizontal offset of the second text',
        widget: 'integer',
      },
      text_2_vertical_offset: {
        title: 'Text 2 Vertical Offset',
        description: 'vertical offset of the second text',
        widget: 'integer',
      }
    
      
      
    },
  
    required: ['text_1_alignment','text_2_alignment', 'image', 'text', 'text2'],
  };
  
  export default IImageWith2TextsSchema;