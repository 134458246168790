/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';

import { Helmet, langmap, flattenToAppURL } from '@plone/volto/helpers';

import config from '@plone/volto/registry';

import { defineMessages, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

import { isBrowser } from 'addons/custom-blocks/src/utilities';

import './language-selector.less';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
});

library.add( faLanguage);


const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  const [displayClass, setDisplayClass] = useState("language-selector ir-language-selector inactive");

  if (isBrowser()) {
    function callback(mutationList, observer) {
      mutationList.forEach(function(mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          // handle class change
          if (mutation.target.className.includes("has-mobile-menu-open")) {
            // console.log(`OPEN -> The class attribute was modified.`, mutation.target.className);
            activate();
          } else {
            // console.log(`CLOSE -> The class attribute was modified.`, mutation.target.className);
            deactivate();
          }
        }
      })
    }
    const observer = new MutationObserver(callback);
    const options = {
      attributes: true
    }
    observer.observe(document.body, options);

  }

  const activate = () => {
    setDisplayClass("language-selector ir-language-selector active");
    // console.log("activate");
  }

  const deactivate = () => {
    setDisplayClass("language-selector ir-language-selector inactive");
    // console.log("deactivate");
  }

  return settings.isMultilingual ? (
    <div className={displayClass} onMouseEnter={() => activate()} onMouseLeave={() => deactivate()}>
      <div className="language-selector-button"><FontAwesomeIcon icon="fa-solid fa-language" /></div>
      <div className='language-selector-links'>
        {map(settings.supportedLanguages, (lang) => {
          const translation = find(translations, { language: lang });
          return (
            <Link
              aria-label={`${intl.formatMessage(
                messages.switchLanguageTo,
              )} ${langmap[lang].nativeName.toLowerCase()}`}
              className={cx({ selected: lang === currentLang })}
              to={translation ? flattenToAppURL(translation['@id']) : `/${lang}`}
              title={langmap[lang].nativeName}
              onClick={() => {
                props.onClickAction();
              }}
              key={`language-selector-${lang}`}
            >
              {langmap[lang].nativeName}
            </Link>
          );
        })}
      </div>  
    </div>
  ) : (
    <Helmet>
      <html lang={settings.defaultLanguage} />
    </Helmet>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
