const IVideoPlayerSchema = {
    title: 'HLS Video Player',
  
    fieldsets: [
      
      {
        id: 'default',
        title: 'Default',
        fields: [
          'title',
          'description', 
          'cta',
          'hidden',
          'video_url',
          'subtitles_url',
          'teaser_url',
          'cover_image'
        ],
      },
      {
        id: 'playOptions',
        title: 'Play Options',
        fields: [
          'autoplay',
          'positioning',
          'proportions',
          'customHeight',
          'customWidth'
          
        ],
      }
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      video_url: {
        title: 'Video URL',
        description: 'Type Video URL',
        widget: 'text',
      },

      teaser_url: {
        title: 'Teaser URL',
        description: 'Type Video Teaser URL',
        widget: 'text',
      },

      subtitles_url: {
        title: 'Subtitles URL',
        description: 'Type Subtitles URL',
        widget: 'text',
      },

      cover_image: {
        title: 'Cover Image',
        description: 'Choose Cover Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      positioning: {
        title: 'Video Positioning',
        description: 'Positioning of video player',
        type: 'choice',
        choices: [['hero', 'Hero'], ['overlay', 'Overlay'], ['section', 'Section']]
      },

      proportions: {
        title: 'Video Proportions',
        description: 'Proportions of video playback',
        type: 'choice',
        choices: [['standard', 'Standard'], ['croppedTL', 'Cropped Top Left'], ['croppedCentre', 'Cropped Centre'], ['croppedCustom', 'Cropped Custom']]
      },

      customHeight: {
        title: 'Custom Height',
        description: 'Custom Height in Pixels',
        widget: 'text',
      },

      customWidth: {
        title: 'Custom Width',
        description: 'Custom Width in Pixels',
        widget: 'text',
      },

      title: {
        title: 'Title',
        description: 'Title to display',
        widget: 'text',
      },
  
      description: {
        title: 'Description',
        description: 'Description to display',
        widget: 'text',
      },

      cta: {
        title: 'Call to Action',
        description: 'Text to acompany play button',
        widget: 'text',
      },

      autoplay: {
        title: 'Auto play video',
        description: 'Play video imediately on load',
        type: 'boolean',
      },
      
    },

    required: ['video_url', ],
  };
  
  export default IVideoPlayerSchema;