
const i18nLabels = {
    "en": {
        "baby": "Baby",
        "boy": "Boy",
        "girl":  "Girl",
        "man": "Man",
        "pregnancy": "Pregnancy",
        "unisex": "Unisex",
        "woman": "Woman",
        "doublelayer": "Doublelayer",
        "fleece": "Fleece",
        "intarsia": "Intarsia",
        "interlock":  "Interlock",
        "jacquard":  "Jacquard",
        "jersey": "Jersey",
        "lace": "Lace",
        "pique":  "Pique",
        "plush":  "Plush",
        "pointelle":  "Pointelle",
        "polar": "Polar",
        "quilt": "Quilt",
        "rib": "Rib",
        "thermoseal": "Thermoseal",
        "woven":  "Woven",
        "body":  "Body",
        "dress": "Dress",
        "loungewear": "Loungewear",
        "overalls": "Overalls",
        "shirt": "Shirt",
        "skirt":  "Skirt",
        "sweatshirts":"Sweatshirts",
        "top": "Top",
        "trousers": "Trousers",
        "vest": "Vest",
        'acetate': 'Acetate',
        'acrylic': 'Acrylic',
        'alginate': 'Alginate',
        'alpaca': 'Alpaca',
        'angora': 'Angora',
        'aramid': 'Aramid',
        'asbestos': 'Asbestos',
        'bamboo': 'Bamboo',
        'beaver': 'Beaver',
        'camel': 'Camel',
        'carbon': 'Carbon',
        'cashmere': 'Cashmere',
        'chlorofibre': 'Chlorofibre',
        'coir': 'Coir',
        'cotton': 'Cotton',
        'cupro': 'Cupro',
        'elastane': 'Elastane',
        'elastodiene':  'Elastodiene',
        'glass': 'Glass',
        'guanaco': 'Guanaco',
        'hemp': 'Hemp',
        'henequen': 'Henequen',
        'jute': 'Jute',
        'kenaf': 'Kenaf',
        'linen': 'Linen',
        'llama': 'Llama',
        'lyocell': 'Lyocell',
        'mauve': 'Mauve',
        'metal': 'Metal',
        'modacrylic': 'Modacrylic',
        'modal': 'Modal',
        'moer': 'Moér',
        'polyamide':  'Polyamide',
        'polyester': 'Polyester',
        'polyethylene': 'Polyethylene',
        'polyimide': 'Polyimide',
        'polypropylene':  'Polypropylene',
        'ramie': 'Ramie',
        'silk': 'Silk',
        'sisal': 'Sisal',
        'sunn': 'Sunn',
        'triacetate':  'Triacetate',
        'vicuna': 'Vicuna',
        'vinyl': 'Vinyl',
        'viscose':'Viscose',
        'wool': 'Wool',
        'yak': 'Yak',
        'embroidery': 'Embroidery',
        'embossing': 'Embossing',
        'printing': 'Printing',
        'digitalprinting': 'Digital Printing',
        'sublimated':  'Sublimated',
        'transfer':  'Transfer',
        'laser':  'Laser',
        'pleated':  'Pleated',
        'washed': 'Washed',
        'dyeing': 'Dyeing',
        'allover': 'All-over',
        "title": "You might also like these looks",
        "noResults": "No results found",
        "moreIn" : "More in",
        "seeSelection" : "See a selection of our production",
    },
    "pt": {
        "baby": "Bebé",
        "boy": "Menino",
        "girl":  "Menina",
        "man": "Homem",
        "pregnancy": "Gravidez",
        "unisex": "Unisexo",
        "woman": "Mulher",
        "doublelayer": "Face Dupla",
        "fleece": "Fleece",
        "intarsia": "Intarsia",
        "interlock":  "Interlock",
        "jacquard":  "Jacquard",
        "jersey": "Jersey",
        "lace": "Lace",
        "pique":  "Pique",
        "plush":  "Plush",
        "pointelle":  "Pointelle",
        "polar": "Polar",
        "quilt": "Quilt",
        "rib": "Rib",
        "thermoseal": "Thermoseal",
        "woven":  "Woven",
        "body":  "Body",
        "dress": "Vestidos",
        "loungewear": "Loungewear",
        "overalls": "Overalls",
        "shirt": "Camisas",
        "skirt":  "Saias",
        "sweatshirts":"Sweatshirts",
        "top": "Top",
        "trousers": "Calças",
        "vest": "Vest",
        'acetate': 'Acetato', 
        'acrylic': 'Acrílico',
        'alginate': 'Alginato',
        'alpaca': 'Alpaca',
        'angora': 'Angora',
        'aramid': 'Aramida',
        'asbestos': 'Amianto',
        'bamboo': 'Bamboo',
        'beaver': 'Beaver',
        'camel': 'Camel',
        'carbon': 'Carbono',
        'cashmere': 'Caxemira',
        'chlorofibre': 'Clorofibra',
        'coir': 'Coir',
        'cotton': 'Algodão',
        'cupro': 'Cupro',
        'elastane': 'Elastano',
        'elastodiene':  'Elastodieno',
        'glass': 'Vidro',
        'guanaco': 'Guanaco',
        'hemp': 'Cânhamo',
        'henequen': 'Henequen',
        'jute': 'Juta',
        'kenaf': 'Kenaf',
        'linen': 'Linho',
        'llama': 'Llama',
        'lyocell': 'Lyocell',
        'mauve': 'Mauve',
        'metal': 'Metal',
        'modacrylic': 'Modacrylic',
        'modal': 'Modal',
        'moer': 'Moér',
        'polyamide':  'Polyamida',
        'polyester': 'Polyester',
        'polyethylene': 'Polyetileno',
        'polyimide': 'Polyimida',
        'polypropylene':  'Polypropileno',
        'ramie': 'Ramie',
        'silk': 'Seda',
        'sisal': 'Sisal',
        'sunn': 'Sunn',
        'triacetate':  'Triacetato',
        'vicuna': 'Vicuna',
        'vinyl': 'Vinil',
        'viscose':'Viscose',
        'wool': 'Lã',
        'yak': 'Yak',
        'embroidery': 'Bordado',
        'embossing': 'Estampado',
        'printing': 'Impressão',
        'digitalprinting': 'Impressão Digital',
        'sublimated':  'Sublimado',
        'transfer':  'Transfer',
        'laser':  'Laser',
        'pleated':  'Pleated',
        'washed': 'Lavado',
        'dyeing': 'Tingido',
        'allover': 'All-over',
        "title": "Veja também estes looks",
        "noResults": "Nenhum resultado encontrado",
        "moreIn" : "Mais em",
        "seeSelection" : "Veja uma selecção da nossa produção",
    }

}

export default i18nLabels;