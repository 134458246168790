import React  from 'react';
import ContentTagView from "../Categories/ContentTagView";
import Link from 'react-router-dom/Link';
import './single-content-card.less';

const SingleContentCardView = (props) => {
    const {
        fullCardLayout = true,
        cardWidth = 350,
        title,
        description,
        image,
        link, 
        uid=`randUID${Math.floor(Math.random() * 1000)}`,
        totals=0,
        fsegments, 
        fgenres,
        fstructures, 
        fcompositions,
        faddons
      } = props;

    // console.log(">>> SingleContentCardView: ", props)

    const cardClass = (fullCardLayout) ? "relatedContentCard full-card" : "relatedContentCard reduced-card";
    const urlComponents = link.split("/");
    const httpRegex = new RegExp("^(http|https)://", "i");
    // test if httpRegex has 2 matches in the link
    const isHttp = (link.match(httpRegex) || []).length > 0;

    const sanitisedLink = isHttp ? `/${urlComponents.slice(3).join("/")}` : link;
    // const sanitisedLink = link; //(process.env.RAZZLE_API_PATH==undefined) ? `/${urlComponents.slice(3).join("/")}` : link;
    // console.log(`>>> SingleContentCardView: totals=${totals}; isHttp=${isHttp}; link=${link}; sanitisedLink=${sanitisedLink}; title=${title}; descr: ${description} `);
    return (
        <div className={cardClass} key={uid} data-width={cardWidth}>
            <div className="related-content-card-image" data-url={sanitisedLink}>
                <Link to={sanitisedLink}><img src={image} loading="lazy" className="related-content-card-cover-image" ></img></Link>
                {/* <a href={link}>
                    <img src={image}></img>
                </a>   */}
            </div>

            <div className="related-content-card-text-container">
                <div className="related-content-card-title">
                    <Link to={sanitisedLink}>
                    {/* <a href={link}> */}
                        <h4>
                            {title} {totals>0 && 
                                <span className="total-items">({totals})</span>
                            }
                        </h4>
                    {/* </a>   */}
                    </Link>
                </div>

                <ContentTagView fsegments={fsegments} fgenres={fgenres} fstructures={fstructures} fcompositions={fcompositions} faddons={faddons} />

                <div className="related-content-card-description">
                    {/* <a href={link}>  */}
                    <Link to={sanitisedLink}>
                        {description}
                    {/* </a>   */}
                    </Link>
                </div>   
            </div>

                   
        </div>
    );
}    

export default SingleContentCardView;
