import React  from 'react';
import Link from 'react-router-dom/Link';
import {useSelector} from 'react-redux';
import i18nLabels from '../i18n';

import './content-tags.less';


const ContentTagView = (props) => {
    const {
        fsegments=null,
        fgenres=null,
        fstructures=null,
        fcompositions=null,
        faddons=null,
        layoutStyle=""
      } = props;


    const defaultLocale = useSelector((state) => state.intl.defaultLocale);
    const locale = useSelector((state) => state.intl.locale);
    const localeToUse = (locale!=null && locale!=undefined && locale.trim().length>0) ? locale : defaultLocale;
    

    const tagIcon = '/clothes-tag.svg';

    const componentClassName = `ContentTagViewContainer ${layoutStyle}`

    const getItemTags = () => {
        const toHtmlTag = (indexer, tagValue, tagTitle) => {
          const label = (i18nLabels[localeToUse]!=undefined) ? i18nLabels[localeToUse][tagValue] : undefined;
          const printableLabel = (label!=null && label!=undefined && label.trim().length>0) ? label : tagTitle;

          // console.log(`toHtmlTag i18nLabels[${localeToUse}][${tagValue}]`, i18nLabels[localeToUse][tagValue])
          return (
            // <a key={tagValue} className='product-tag' href={`/search?${indexer}=${tagValue}`}>{tagTitle}</a>
            <Link key={tagValue} className='product-tag' to={`/search?${indexer}=${tagValue}&Language=${localeToUse}`}>{printableLabel}</Link>
          )
        }
        const t_markets = (fsegments!=null) ? fsegments.map((s) => toHtmlTag('fashionProductSegments', s.token, s.title)) : [];
        const t_genres = (fgenres!=null) ? fgenres.map((s) => toHtmlTag('fashionProductGenres', s.token, s.title)) : [];
        const t_structures = (fstructures!=null) ? fstructures.map((s) => toHtmlTag('fashionProductStructures', s.token, s.title)) : [];
        const t_compositions = (fcompositions!=null) ? fcompositions.map((s) => toHtmlTag('fashionProductCompositions', s.token, s.title)) : [];
        const t_addons = (faddons!=null) ? faddons.map((s) => toHtmlTag('fashionProductAddons', s.token, s.title)) : [];
    
        return [t_markets, t_genres, t_structures, t_compositions, t_addons].flat();
      
      }

    const items = getItemTags();

    return (items.length > 0 &&
      <div className={componentClassName}>
        <div className='related-content-card-tags'>
            <div className='tag-icon'>
                <img src={tagIcon}/>
            </div>
            <div className='tag-names'>
                {items}
            </div>
            <div className="tag-appendix">&nbsp;</div>
        </div>
      </div>  
    );

}    

export default ContentTagView;
