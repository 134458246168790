const englishQuestions = {
    'QUANTITIES': {
        'title': 'QUANTITIES',
        'question': 'What are your minimum order quantities?',
        'answer': 'We generally require a minimum order quantity of 1000 units per colour. However, we can sometimes accept smaller orders. Please contact us for more information.',
        'position': 0
    },

    'LEAD TIME': {
        'title': 'LEAD TIME',
        'question': 'What is your lead time?',
        'answer': 'Our lead time is 6-8 weeks, from order placement to units being dispatched from our factory. The first prototype can usually be executed within one week.',
        'position': 1
    },

    'CERTIFICATIONS': {
        'title': 'CERTIFICATIONS',
        'question': 'Besides your existing certifications, could you seek aditional certifications required by our business?',
        'answer': 'It depends. Certification tends to be a long and complex process and we need to understand the requirements of your business. Please contact us to discuss your specific requirements.',
        'position': 2
    },
}

const portugueseQuestions = {
    'QUANTITIES': {
        'title': 'QUANTIDADES',
        'question': 'Qual é a quantidade mínima de encomenda?',
        'answer': 'Geralmente requeremos uma encomenda mínima de 1000 unidades por cor. No entanto, às vezes podemos aceitar encomendas menores. Por favor, contacte-nos para analisarmos os seus requisitos específicos.',
        'position': 0
    },

    'LEAD TIME': {
        'title': 'TEMPO DE ENTREGA',
        'question': 'Qual é o tempo de entrega?',
        'answer': 'O nosso tempo de entrega é de 6-8 semanas, desde a colocação do pedido até às unidades serem despachadas da nossa fábrica. O primeiro protótipo pode, geralmente, ser executado no prazo de uma semana.',
        'position': 1
    },

    'CERTIFICATIONS': {
        'title': 'CERTIFICAÇÕES',
        'question': 'Para além das vossas certificações existentes, poderiam procurar certificações adicionais necessárias ao nosso negócio?',
        'answer': 'Depende. A certificação tende a ser um processo longo e complexo e precisamos de entender os requisitos do seu negócio. Por favor, contacte-nos para mais informações.',
        'position': 2
    },
}

const internationalQuestions = {
    'en': englishQuestions,
    'pt': portugueseQuestions,
}

export default internationalQuestions