const IImageGallerySchema = {
    title: 'ImageGallery',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'dark',
          'title',
          'images',
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      dark: {
        title: 'Dark theme',
        description: 'Shows gallery on dark background',
        type: 'boolean',
      },

      title: {
        title: 'Title',
        description: 'Title of the Image Gallery',
        type: 'string',
      },

      images: {
        title: 'Portrait Image',
        description: 'Choose Portrait Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'multiple'
      },
      
    },
  
    required: ['images'],
  };
  
  export default IImageGallerySchema;