/**
 * Home container.
 * @module components/theme/NotFound/NotFound
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import RelatedContentCardsView from '../../../../addons/custom-blocks/src/RelatedContentCards/RelatedContentCardsView';
import './page-not-found.less';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => (
  <div id="page-not-found">
    <Container className="view-wrapper" >
      <h1>Ooops...</h1>
      <div className="description">
        {/* <FormattedMessage
            id="This page does not seem to exist…"
            defaultMessage="This page does not seem to exist…"
          /> */}
          <FormattedMessage
              id="We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for."
              defaultMessage="We apologize for the inconvenience, but the page you were trying to access is not at this address. You can use the links below to help you find what you are looking for."
            />
      </div>
    </Container>
    <RelatedContentCardsView 
        data={{
          title: 'Why not explore some of our creations?',
          selector: undefined, 
          groupByTaxonomy:'glisco.plone.ecommerce.fashion.product.genres'
        }}

        properties={{}}
      />
  </div>
);

export default withServerErrorCode(404)(NotFound);
