const IImageIRSchema = {
    title: 'ImageIR',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'image',
          'alignment',
          'size',
          'no_top_margin',
          'no_bottom_margin',
        ],
      },
    ],
  
    properties: {
      
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },

      alignment: {
        title: 'Alignment',
        description: 'Alignment of the image',
        type: 'choice',
        choices: [['left', 'Left'], ['right', 'Right'], ['full', 'Full']]
      },

      size: {
        title: 'Size',
        description: 'Size of the image',
        type: 'choice',
        choices: [['small', 'Small'], ['medium', 'Medium'], ['large', 'Large']],
        default: 'medium'
      },

      no_top_margin: {
        title: 'No Top Margin',
        description: 'No top margin',
        type: 'boolean',
        default: false,
      },

      no_bottom_margin: {
        title: 'No Bottom Margin',
        description: 'No bottom margin',
        type: 'boolean',
        default: false,
      },

    },
  
    required: ['image', 'alignment'],
  };
  
  export default IImageIRSchema;