const IHighlightBlockSchema = {
    title: 'HighlightBlock',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'image',
          'title',
          'bold_words',
          'image_text_segment',
          'text_left',
          'cta',
          'target_url'
        ],
      },
    ],
  
    properties: {
      image: {
        title: 'Image',
        description: 'Choose Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },
      title: {
        title: 'Title',
        description: 'Title',
        widget: 'text',
      },
      bold_words: {
        title: 'Bold Words',
        description: 'Words to be bolded, seperated by comma',
        widget: 'textarea',
      },
      image_text_segment: {
        title: 'Image Text Segment',
        description: 'Words to be over image',
        widget: 'textarea',
      },

      text_left: {
        title: 'Text Left',
        description: 'Align text left',
        type: 'boolean',
      },

      cta: {
        title: 'Call to Action',
        description: 'Call to Action / Link Text',
        widget: 'text',
      },

      target_url: {
        title: 'Target URL',
        description: 'Choose Target Page for Link',
        widget: 'object_browser',
        selectableTypes : ['page'], 
        mode: 'link'
      },
      
    },
  
    required: ['image', 'title'],
  };
  
  export default IHighlightBlockSchema;