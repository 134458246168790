import React, { useEffect, useRef, useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { forceSameDomain } from '../utilities';

import './highlight-block.less';
import $ from 'jquery';


const HighlightBlockView = (props) => {
  let ref = useRef();
  let description = "";

  var image = "";
  if (props.data.image != undefined) {
    try {
      image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
      
    } catch (e) {
      image = "";
    }
    
  }

  const populated = (value) => {
    return value != undefined && value != null && value.length > 0;
  }

  const text = (props.data.title != undefined && props.data.title.length > 0) ? props.data.title : "Injustice anywhere is a threat to justice everywhere";
  const highlights = (props.data.bold_words != undefined && props.data.bold_words.length > 0) ? props.data.bold_words.split(",").map(w=>w.trim()) : ["anywhere", "everywhere"];

  const imageText = populated(props.data.image_text_segment) ? props.data.image_text_segment : null;
  const cta = populated(props.data.cta) ? props.data.cta : null;
  const link = populated(props.data.target_url) ? props.data.target_url[0].getURL  : null

  const textContent = () => {
    const elements = text.split(" ").map((w) => {
      const segment =  (highlights.includes(w)) ? `<i>${w}</i>` : w;
      // console.log(`processing segmet for word ${w}. segment = ${segment}`)
      // console.log(segment);
      return segment;
    }).join(" ");

    return elements;
  }

  return( 
  <div className="highlight-block" ref={ref}>
    <div className='highlight-block-content-container'>
      <div className="highlight-block-title-container" >
        <h2  dangerouslySetInnerHTML={{ __html: textContent() }} />
      </div>
      <div className='highlight-block-image-container'>
          <div className='text-container'>
              {imageText!=null &&
                <h3>{imageText}</h3>
              }
              <div className='cta'>
                { link!=null && 
                    <a href={link}>{cta} <span><FontAwesomeIcon icon={faArrowRight} /></span></a>
                }    
                </div>
          </div>
          
          <img src={image} alt=""/>
      </div>
    </div>  
  </div>)
};

export default HighlightBlockView;