/** Image Gallery actions.
* @module actions/content_types/get_pages
*/

import { GET_PENDING_IMAGES, SET_PENDING_IMAGES, REGISTER_PENDING_IMAGES, REGISTER_IMAGE_LOADED } from '../../constants/ActionTypes';

/**
* Get FAQ items.
* @function setPendingImages
* @returns {Object} Images action.
*/
export function getPendingImages(page) {
  return {
    type: GET_PENDING_IMAGES,
    page, };
}

export function setPendingImages(page, number, images=[], allLoaded=false) {
  const newImages = new Set(images);
  return {
    type: SET_PENDING_IMAGES,
    page,
    number,
    images:newImages,
    allLoaded
 };
}

export function registerPendingImages(page, images=[]) {
  const newImages = new Set(images);
  const number = newImages.size;
  return {
    type: REGISTER_PENDING_IMAGES,
    page,
    number,
    images: newImages,
    allLoaded:false
 };
}

export function registerImagesLoaded(page, images) {
  const newImages = new Set(images);
  const number = 1;
  return {
    type: REGISTER_IMAGE_LOADED,
    page,
    number,
    images: newImages,
    allLoaded:false
 };
}