const images = {
    files: [
        {
            // generate a random string id for each image
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),            
            name: "L1",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 1600
        },
        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "L2",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 1600
        },
        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "L3",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 1600
        },
        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "P1",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 3000
        },
        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "P2",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 3000
        },

        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "P3",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 3000
        },
        {
            // generate a random string id for each image
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),            
            name: "L4",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 1600
        },
        {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            name: "L5",
            url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
            width: 2000,
            height: 1600
        },

    ]
}

const grid = {
    rows: 2,
    columns: 4,
    currentRow: 0,
    currentColumn: 0,
    images: images,
    layouts: {}
}

export { grid, images}