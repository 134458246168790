/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';
import gliscoIrcustomBlocks from '@glisco-ir/custom-blocks';

const addonsInfo = [{"name":"@eeacms/volto-columns-block","version":"4.5.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/Users/joemedicis/Development/Clients/Irmaos Rodrigues/Volto/github/irmaos-rodrigues/ir-project/node_modules/@eeacms/volto-columns-block/src","packageJson":"/Users/joemedicis/Development/Clients/Irmaos Rodrigues/Volto/github/irmaos-rodrigues/ir-project/node_modules/@eeacms/volto-columns-block/package.json","addons":[]},{"modulePath":"/Users/joemedicis/Development/Clients/Irmaos Rodrigues/Volto/github/irmaos-rodrigues/ir-project/src/addons/custom-blocks/src","packageJson":"/Users/joemedicis/Development/Clients/Irmaos Rodrigues/Volto/github/irmaos-rodrigues/ir-project/src/addons/custom-blocks/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"@glisco-ir/custom-blocks","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoColumnsBlock, gliscoIrcustomBlocks];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
