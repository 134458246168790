import React, { useEffect, useRef, useState }  from 'react';
// import { Controller, Scene } from 'react-scrollmagic';
// import { Tween, Timeline } from "react-gsap";
// import $ from 'jquery';
import { useSelector } from 'react-redux';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import {shouldAnimate, 
        triggerScrollTriggerRefresh, 
        changeInlineCss, 
        changeCssClasses, 
        isBrowser, 
        bodyPercentToAbsolutePositioning,
        forceSameDomain} from '../utilities';

import './image-with-text.less';

import imgPlaceHolder from '../../../../../public/2k3kplaceholder.jpg';

const [UNLOADED, INIT, RUNNING, PAUSED, STOPPED, OBSERVING] = [-1,0,1,2,3,4];

const ImageWithTextView = (props) => {

  gsap.registerPlugin(ScrollTrigger);
  ScrollTrigger.defaults({
    // markers: true,
    scrub: true,             
    // toggleActions: "play pause reverse pause",
    toggleActions: "play none none none",
    // play, pause, resume, reverse, restart, reset, complete, none
    // 4 values set to pastStartPoint pastEndPoint reEnter scrollBackPastTheStart
  });

  if (isBrowser())  window.history.scrollRestoration = 'manual';
  
  let text = props.data.text;

  const myId = useRef(`${Math.floor(Math.random() * 10000)}`);
  const myPinnedContainerLabel = `pinned-container-${myId.current}`;
  const myReduce65Label = `reduce65-image-${myId.current}`;
  const alignment = (props.data.alignment != undefined && props.data.alignment!=null) ? props.data.alignment : "left";
  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;
  
  const ref = useRef();

  const [imageToUse, setImageToUse] = useState(imgPlaceHolder);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [overalContainerStatus, setOverallContainerStatus] = useState(UNLOADED);
  const [containerFixRunning, setContainerFixRunning] = useState(INIT);
  const [reduceImageRunning, setReduceImageRunning] = useState(INIT);
  const [bringTextInRunning, setBringTextInRunning] = useState(INIT);
  const [releaseFixedTextRunning, setReleaseFixedTextRunning] = useState(INIT);
  const [scrollerControlRunning, setScrollerControlRunning] = useState(INIT);

  const [imageContainerInline, setImageContainerInline] = useState({});
  const [componentContainerClass, setComponentContainerClass] = useState("image-with-text initial " + alignment);
  const [componentContainerInline, setComponentContainerInline] = useState({});

  const [textContainerClass, setTextContainerClass] = useState("text-container initial");
  const [textContainerInline, setTextContainerInline] = useState({});
  const [textInline, setTextInline] = useState({});

  const [loaderControlCount, setLoaderControlCount] = useState(0);
  
  const scrollControllerId = useRef(`scrollController-${myPinnedContainerLabel}`);
  const pinScrollerId = useRef(`pinScroller-${myPinnedContainerLabel}`);
  const reduce65ScrollerId = useRef(`reduce65Scroller-${myReduce65Label}`);
  const bringTextInScrollerId = useRef(`bringTextInScroller-${myReduce65Label}`);
  const releaseStickyTextScrollerId = useRef(`releaseStickyTextScroller-${myReduce65Label}`);
  const initialScrollPosition = useRef(0);
  const timelinesAdded = useRef([]);

  const imageRef = useRef();

  const getImageUrl = () => {
    try {
      return forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image")
    } catch (ex) {
      console.log(`Error in getImageUrl: `, ex, props.data);
      return imageToUse;
    }
  }

  const loadAndSetImage = (from=`unknown`) => {
    // console.log(`loadAndSetImage called from ${from}`);
    // const image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
    setImageToUse(getImageUrl());
  }

  const initialiseComponentAnimation = () => {
    if(imageRef.current && imageRef.current.complete) {
      setImageLoaded(true);
    }
  }

  useEffect(() => {
    if (imageLoaded && overalContainerStatus==UNLOADED) 
      setOverallContainerStatus(INIT);
  }, [imageLoaded]);

  useEffect (() => {
    // console.log(`>>>>.....>>>>> pendingImages is ${pendingImages} and loaded is ${loaded} and isBrowser is ${isBrowser()} and overalContainerStatus is ${overalContainerStatus}`);
    // console.log(`useEffect[overalContainerStatus][${overalContainerStatus}] ${myPinnedContainerLabel} visible is ${visible} and editEnabled is ${editEnabled}`);
    if (isBrowser() && overalContainerStatus==INIT) {
      const cssOptions = (alignment=='right') ? {right: '65%'} : {left: '65%'};
      const animate = shouldAnimate(imageRef.current.naturalWidth, imageRef.current.naturalHeight, 0.65);

      // console.log(`useEffect[overalContainerStatus][INIT] animate is ${animate} and visible is ${visible} and editEnabled is ${editEnabled}`);

      /**********************************************************************************
      
      IMAGE LOADING WITH GSAP
      
      We're trying to get rid of a PageLoaderInsertion which takes over the screen on 
      EVERY page load, resulting in a suboptimal user experience. 

      NOTE: The Page Loader Insertion is still there and being used to count images and 
      declare the page ready. But it no longer blocks the screen 

      To do that, we're experimenting with the following strategy:

      1. When this component loads, the image is not the final image but rather a
         placeholder with 2k width and 3k wide (pixels), as are the images that will 
         eventually load. 
    
      2. At some point we need to load the actual image. We have two options here:

        a) useEffect (..., []), which means all will try to load when the components
           get mounted, which means the one closest to the top might not be the first 
           one to load; or... 
        
        b) use gsap scroll effects and load on an as-needed basis, for example, when the 
           component is less that 1.5 vertical screens away from being displayed. 
      
      3. This means we'll need scroll triggers for mobile devices, which we currently 
         do not use. 
      ***********************************************************************************/

      // Set a new gsap context regardless of context, and set a timeline for when the 
      // the component is full screen height + 800px. So, when the image is 800px below
      // the bottom of the screen it gets loaded. 
      gsap.context(() => {
        gsap.timeline()
                .to(".image-container", {
                  scrollTrigger: {
                    markers: false,
                    trigger: ".image-container",
                    start: `top bottom+=1500px`,
                    onEnter: (self) => {
                      try {
                        loadAndSetImage('GSAP onEnter imgLoadingContext')
                      } catch (e) {
                        console.log(`error loading image in ImageWithTextView`, e);
                      }
                      
                    },

                  }
                })
      }, ref);

      if (animate && visible && !editEnabled) {
        
        // annotatedLog(`    container offset is ${ref.current.offsetTop}`, `scrollPosition is ${window.pageYOffset}`, scrollControllerId.current);

        if (timelinesAdded.current.indexOf(scrollControllerId.current) == -1) {
          // annotatedLog(">>>>.....>>>>> new ctx", "create gsap context", scrollControllerId.current);
          const ctx = gsap.context(() => {
            // console.log(">>>>.....>>>>> new ctx", "create gsap context", scrollControllerId.current);
            gsap.timeline()
              .addLabel("scrollController")
              .to(".image-with-text-scroller", {
                scrollTrigger: {
                  markers: false,
                  id: scrollControllerId.current,
                  trigger: '.image-with-text-scroller span',
                  start: `top 100px`,
                  end: (self) => `+=50`,
                  onEnter: (self) => {
                    setScrollerControlRunning(RUNNING);
                    // console.log("ENTERED scrollController");
                  },
            
                  // onLeave: (self) => {
                  //   console.log("LEFT scrollController");
                  // },
      
                  onLeaveBack: (self) => {
                    // console.log("LEAVE BACK scrollController");
                    setScrollerControlRunning(OBSERVING);
                    // setReleaseFixedTextRunning(RUNNING);
                    // setContainerFixRunning(INIT);
                  }
                }
              })
      
              .addLabel("fixContainer")
              .to(".image-with-text-scroller", {
                scrollTrigger: {
                  markers: false,
                  id: pinScrollerId.current,
                  trigger: '.image-with-text-scroller span',
                  start: `top top`,
                  end: (self) => `+=700`,
                  onEnter: (self) => {
                    setContainerFixRunning(RUNNING);
                  },
            
                  onLeave: (self) => {
                    setContainerFixRunning(STOPPED);
                  },
      
                  onLeaveBack: (self) => {
                    setContainerFixRunning(INIT);
                  }
                }
              })
              
              .addLabel("reduce65")
                .to(".image-container", {
                  width: '65%', 
                  minWidth: '65%',
                  scrollTrigger: {
                    markers: false,
                    id: reduce65ScrollerId.current,
                    trigger: ".image-with-text-scroller span",
                    start: (self) => `top -50px`, 
                    end: (self) => `+=400`,
                    onEnter: (self) => {
                      // console.log(`onEnter reduceImageRunning is ${reduceImageRunning} for scroller ${reduce65ScrollerId.current}`);
                      setReduceImageRunning(RUNNING);
                    },
                    onLeave: (self) => {
                      // console.log(`onLeave reduceImageRunning is ${reduceImageRunning} for scroller ${reduce65ScrollerId.current}`);
                      setReduceImageRunning(STOPPED);
                    }
                  }    
                })
      
              .addLabel("bringTextIn")
                .to(`.image-with-text .text-container .text` , {
                  ...cssOptions,
                  scrollTrigger: {
                    id: bringTextInScrollerId.current,
                    markers: false,
                    trigger: ".image-with-text-scroller span",
                    start: (self) => `top -350px`, 
                    end: (self) => `+=290`,
                    onEnter: (self) => {
                      setBringTextInRunning(RUNNING);
                    },
                    onLeave: (self) => {
                      setBringTextInRunning(STOPPED);
                    },
                  }    
                }) 
      
              .addLabel("releaseFixedText")
                .to(`.image-with-text .image-container` , {
                  // left: '65%',
                  scrollTrigger: {
                    id: releaseStickyTextScrollerId.current,
                    markers: false,
                    trigger: ".image-with-text .image-container",

                    start: (self) => `bottom bottom`, 
                    end: (self) => `+=10`,
                    
                    onEnter: (self) => {
                      // console.log("ENTERED releaseFixedText RUNNING");
                      setReleaseFixedTextRunning(RUNNING);
                    },
                    onLeave: (self) => {
                      // console.log("LEFT releaseFixedText RUNNING");
                      setReleaseFixedTextRunning(STOPPED);
                    }
                  }    
                }); 
      
            return () => {
              // console.log(`Image With Text Cleanup Called`);
            };
        
          }, ref); 

          timelinesAdded.current.push(scrollControllerId.current);
        }
        // <-
        setOverallContainerStatus(RUNNING);

      } else {
        // console.log(`useEffect[overalContainerStatus][${overalContainerStatus}] animate=${animate}, visible=${visible}; editEnabled=${editEnabled}`)
        if (editEnabled) {
          changeCssClasses( componentContainerClass, setComponentContainerClass, ["on-edit"], [""]); 
        }
      }
    } else {
      // console.log(`useEffect[overalContainerStatus][${overalContainerStatus}], imageLoaded=${imageLoaded}`)
    }

  }, [overalContainerStatus]);

  useEffect(() => {

    if (containerFixRunning==RUNNING) {
      // annotatedLog(`    containerFixRunning on scrollOffset=${window.scrollY}`, "RUNNNING", pinScrollerId.current);
      // console.log(`    containerFixRunning RUNNNING on scrollOffset=${window.scrollY}`, pinScrollerId.current);

      const body = document.getElementsByTagName("body")[0];
      const bodyWidth = body.offsetWidth;
      const viewportWidth = window.innerWidth;
      const leftMargin = (viewportWidth-bodyWidth)/2;
      const scroller = ScrollTrigger.getById(pinScrollerId.current);    
      const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
      const previousSibling = containerBlock.previousSibling;
          
      initialScrollPosition.current = window.pageYOffset;

      // console.log(`setting initialScrollPosition to ${initialScrollPosition.current}`);


      changeCssClasses( componentContainerClass, setComponentContainerClass, ["fixed"], ["initial"]);

      changeInlineCss(componentContainerInline, 
                      setComponentContainerInline, 
                      {
                        left: `${leftMargin}px`,
                        width: `${bodyWidth}px`
                      }, 
                      []
                      );

      if (previousSibling!= null && previousSibling!= undefined) {
        previousSibling.style.marginBottom = `300vh`;
      }

    }

    if (containerFixRunning==PAUSED || containerFixRunning==STOPPED ) {
      // annotatedLog("containerFixRunning", "STOPPED/PAUSED", pinScrollerId.current);
      // console.log("containerFixRunning STOPPED/PAUSED", pinScrollerId.current);
      const scroller = ScrollTrigger.getById(pinScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const previousSibling = containerBlock.previousSibling;

        changeCssClasses( componentContainerClass, setComponentContainerClass, ["reduce65", "loose"], ["fixed", "text-fly-in"]); 
        changeInlineCss(componentContainerInline, setComponentContainerInline, {}, ["width", "left"]);

        changeInlineCss(textInline, setTextInline, {}, ["left"]);
        
        previousSibling.style.marginBottom = `0`;

        window.scrollTo(0, initialScrollPosition.current);
        scroller.kill();
        triggerScrollTriggerRefresh([releaseStickyTextScrollerId.current]);
        // ScrollTrigger.refresh()
      }
      // fx_fixContainer.kill();
    } 

    if (containerFixRunning==INIT) {
      // annotatedLog("containerFixRunning", "INIT", pinScrollerId.current);
      // console.log("containerFixRunning INIT", pinScrollerId.current);
      const scroller = ScrollTrigger.getById(pinScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const previousSibling = containerBlock.previousSibling;

        changeCssClasses( componentContainerClass, setComponentContainerClass, [], ["fixed"]); 

        previousSibling.style.marginBottom = `0`;
        // window.scrollTo(0, initialScrollPosition.current);
        // scroller.kill();
        triggerScrollTriggerRefresh([releaseStickyTextScrollerId.current]);
        
      }


    }
    
  }, [containerFixRunning]);

  useEffect(() => {

    if (reduceImageRunning==RUNNING ) {
      // console.log(">>>> reduceImageRunning RUNNING");
      // annotatedLog("reduceImageRunning", "RUNNING", reduce65ScrollerId.current);

    }

    if (reduceImageRunning==PAUSED ||reduceImageRunning==STOPPED ) {
      // console.log(">>>> reduceImageRunning STOPPED");
      // annotatedLog("reduceImageRunning", "PAUSED/STOPPED", reduce65ScrollerId.current);
      const scroller = ScrollTrigger.getById(reduce65ScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        scroller.kill();
      }
      // fx_reduceImage.revert();
      // fx_reduceImage.kill();

      changeCssClasses(componentContainerClass, setComponentContainerClass, ["reduce65"], ["initial"]);
      changeInlineCss(imageContainerInline, setImageContainerInline, {}, ['width', 'minWidth']);

    }

    
  }, [reduceImageRunning]);

  useEffect(() => {

    if (bringTextInRunning==RUNNING) {
      // annotatedLog("bringTextInRunning", "RUNNIING", bringTextInScrollerId.current);
      const scroller = ScrollTrigger.getById(bringTextInScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        const body = document.getElementsByTagName("body")[0];
        const bodyWidth = body.offsetWidth;
        const viewportHeight = window.innerHeight;
      
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const textContainer = containerBlock.querySelector(".text-container");
        const text = textContainer.querySelector(".text");
        const textWidth = 0.35*bodyWidth - 70;
        const textHeight = text.clientHeight + 4*50; // Add some margin
        const textReductionFactor = (textHeight>viewportHeight) ? (1.0*textHeight)/viewportHeight : 1;
        const textPos = Math.max(0, viewportHeight-(text.clientHeight/textReductionFactor)-(4*54) );
        
        
        changeCssClasses( componentContainerClass, setComponentContainerClass, ["text-fly-in"], []); 
        changeCssClasses( textContainerClass, setTextContainerClass, ["animated"], ["initial"]); 

        changeInlineCss(textContainerInline, setTextContainerInline, {
          width: `${textWidth}px`
        }, []);

        changeInlineCss(textInline, setTextInline, {
          width: `${textWidth}px`,
          fontSize: `${48/textReductionFactor}px`,
          lineHeight: `${54/textReductionFactor}px`,
          top: `${textPos}px`
        }, []);
      }
    } else 

    if (bringTextInRunning==PAUSED ||bringTextInRunning==STOPPED ) {
      // annotatedLog("bringTextInRunning", "STOPPED/PAUSED", bringTextInScrollerId.current);
      const scroller = ScrollTrigger.getById(bringTextInScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        let hposTextContainer = {};
        let hposText = {};
        scroller.kill();

        if (containerBlock.classList.contains("left")) {
          hposTextContainer["left"] = `${bodyPercentToAbsolutePositioning(65)}px`;
          hposText["left"] = 0;
        } else if (containerBlock.classList.contains("right")) {
          hposTextContainer["right"] = `${bodyPercentToAbsolutePositioning(65)}px`;
          hposText["right"] = 0;
        }

        changeCssClasses( textContainerClass, setTextContainerClass, ["sticky"], ["animated"]); 
        changeInlineCss( textContainerInline, setTextContainerInline, {
          ...hposTextContainer
        }, []);
        changeInlineCss(textInline, setTextInline, {
          ...hposText
        }, []);
      
      }
      // fx_bringTextIn.kill();
    } else {
      // annotatedLog("bringTextInRunning = " + bringTextInRunning, "????", bringTextInScrollerId.current);
    }
    
  }, [bringTextInRunning]);

  useEffect(() => {
    if (releaseFixedTextRunning==RUNNING) {
      const scroller = ScrollTrigger.getById(releaseStickyTextScrollerId.current);
      // annotatedLog("****** releaseFixedTextRunning", "RUNNING", releaseStickyTextScrollerId.current);
      if (scroller!=undefined && scroller!=null) {

        changeCssClasses( textContainerClass, setTextContainerClass, ["final"], ["sticky"]); 
        changeInlineCss(textContainerInline, setTextContainerInline, {
          // textContainer.style.width = `${0.35*bodyWidth}px`;
          // width: `${0.35*bodyWidth}px`
        }, ["left", "right"]);

        changeInlineCss(textInline, setTextInline, {
          bottom: "0px"
        }, ["top"]);

      }
    } else 

    if (releaseFixedTextRunning==PAUSED ||releaseFixedTextRunning==STOPPED ) {
      // annotatedLog("****** releaseFixedTextRunning", "PAUSED/STOPPED", releaseStickyTextScrollerId.current);
      const scroller = ScrollTrigger.getById(releaseStickyTextScrollerId.current);
      if (scroller!=undefined && scroller!=null) {
        scroller.kill();
        ScrollTrigger.refresh();
      }
    } else {
      // annotatedLog("****** releaseFixedTextRunning = " + releaseFixedTextRunning, "????", releaseStickyTextScrollerId.current);
    }
    
  }, [releaseFixedTextRunning]);

  useEffect(() => {

    if (scrollerControlRunning==OBSERVING && bringTextInRunning==STOPPED) {
        // annotatedLog("scrollerControlRunning", "PAUSED/STOPPED", scrollControllerId.current);
        const scroller = ScrollTrigger.getById(scrollControllerId.current);

        const containerBlock = scroller.trigger.parentNode.parentNode.parentNode;
        const textContainerBlock = containerBlock.children[0].children[3];
        const isFinal = textContainerBlock.classList.contains("final");
        // console.log("scrollerControlRunning containerBlock . Is final: " + isFinal);
        // console.log(containerBlock);
        if (!isFinal) {
          changeCssClasses( textContainerClass, setTextContainerClass, ["flowing"], ["sticky"]); 
        }
        
      
    }
    
  }, [scrollerControlRunning]);

  useEffect(() => {
    const isElementInViewport = (el)  => {
      var rect = el.getBoundingClientRect();
      return (
          // rect.top >= 0  // top of image is below the top of the screen
          // rect.left >= 0 &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) // top of image is above the bottom of the screen 
          // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
          // rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      )
    }

    const isVisible = isElementInViewport(imageRef.current);
    if (isVisible) {
      if (imageToUse == imgPlaceHolder) {
        console.log(`>>> correcting non loaded image on ${myPinnedContainerLabel}`)
        loadAndSetImage('useEffect[loaderControlCount]');
      }
      // console.log(`******** >>>>>>>>> ******** setInterval[${myPinnedContainerLabel}][${loaderControlCount}]: pendingImages=${pendingImages}; imageToUse=${imageToUse}; isVisible=${isVisible}`);
    }
    

  }, [loaderControlCount]);

  useEffect(() => {
    initialiseComponentAnimation();

    const intervalId = setInterval(() => {
      setLoaderControlCount(Math.floor(Math.random() * 100));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const boldWords = (props.data.bold_words != undefined) ? props.data.bold_words : "";  
  var boldWordsArray = boldWords.split(",");
  for (var i = 0; i < boldWordsArray.length; i++) {
    var boldWord = boldWordsArray[i];
    var regex = new RegExp(boldWord, 'g');
    try {
      text = text.replace(regex, '<span style="font-weight: 600">' + boldWord + '</span>');
    } catch (e) {
      text="";
    }
    
  }
  
  if (!visible && !editEnabled) {
    return (<div ref={ref} id={myPinnedContainerLabel} className={componentContainerClass}>visibility flag is set to {visible}</div>)
  } else {
    return( <div ref={ref} id={myPinnedContainerLabel} className={componentContainerClass} style={componentContainerInline}>
        <div className="block-container" >
          <div className='image-with-text-scroller'><span>&nbsp;</span></div>

          <div className="image-container lazyloader" style={imageContainerInline}>
            <img ref={imageRef} className={`imageWithTextImage`} loading="lazy" src={imageToUse} alt="" 
            data-target-image={getImageUrl()}
            onLoad={(event) => setImageLoaded(true)}
            />
          </div>
          <div className='text-banner-support  hidden'/>
          <div className={textContainerClass} style={textContainerInline}>
            <div className="text" style={textInline}>
                <span></span>
                          
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </div>
        </div>

      </div>)
  }  
          
};

export default ImageWithTextView;






















