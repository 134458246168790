import React  from 'react';
import './faq-question.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import {rand} from '../utilities';

const SingleQuestionView = (props) => {
    const {
        keyToUse=(rand(1000)),
        number=0,
        title='',
        question='',
        answer='',
        theme='light',
        activeQuestion=-1,
        setActiveQuestion,
      } = props;

    const isActive = (number === activeQuestion) ? 'active' : 'inactive';
    const componentClassName = `SingleQuestionView ${theme} ${isActive}`;
    const twoDigits = (n) => {
        return (n < 10) ? `0${n}` : n;
    }

    const toggleActiveQuestion = (evt) => {
        evt.preventDefault();
        if (number === activeQuestion) {
            setActiveQuestion(-1);
        } else {
            setActiveQuestion(number);
        }
    }

    const toggleIcon = (number === activeQuestion) ? faMinus : faPlus;

    return (
        <div className={componentClassName} key={keyToUse} id={keyToUse}>
            <div className='question-header'>
                <div className='question-number'>{twoDigits(number)}</div>
                <div className='question-title'>{title}</div>
                <div className="question-expander"><a href="" onClick={(evt) => toggleActiveQuestion(evt)}><FontAwesomeIcon icon={toggleIcon} /></a></div>
            </div>
            <div className='question-content'>
                <div className='question'>{question}</div>
                <div className='answer'>{answer}</div>
            </div>
        </div>       
    );
}    

export default SingleQuestionView;