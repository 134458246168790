import React, { useEffect, useRef, useState }  from 'react';
import './image-ir.less';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from "react-gsap";
import { forceSameDomain } from '../utilities';


const ImageIRView = (props) => {
  let ref = useRef()

  const standardiseUrl = (url) => { 
    let imgUrl =  forceSameDomain(url.replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image"); 
    
    return imgUrl;
}

  const image = (props.data.image != undefined) ? standardiseUrl(props.data.image[0]["getURL"]) : "";
  
  const alignment = (props.data.alignment != undefined) ? props.data.alignment : "full";
  const size = props.data.size;
  const noTopMargin = (props.data.no_top_margin != undefined) ? props.data.no_top_margin.toString() : "false"; 
  const noBottomMargin = (props.data.no_bottom_margin != undefined) ? props.data.no_bottom_margin.toString() : "false";
  
  const blockClass = "image-ir";
  

  return( 
    <div className={blockClass} alignment={alignment} size={size} no-top-margin={noTopMargin} no-bottom-margin={noBottomMargin}>
      <img src={image} alt="" />
    </div>
  )
};

export default ImageIRView;